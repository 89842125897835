import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import secureLocalStorage from 'react-secure-storage';
import { getBaseURL } from '../../../pages/api-helper/api-header-config';

const API_URL = process.env.REACT_APP_API_URL
const LOGIN_API_URL = process.env.REACT_APP_CENTRAL_API_URL
let current_url = window.location.href;

getBaseURL(current_url);
export const GET_USER_BY_ACCESSTOKEN_URL = `${secureLocalStorage.getItem("baseURL")}/api/verify-token`
export const LOGIN_URL = `${LOGIN_API_URL}/api/login`
export const GET_USER_PERMISSIONS = `${secureLocalStorage.getItem("baseURL")}/api/user-permissions/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${LOGIN_API_URL}/api/forget-password`

// all permissions of logged in user
export const get_user_permissions = async () => {
  let response = await axios.get(`${secureLocalStorage.getItem("baseURL")}/api/user-permissions`);
  return response.data;
}
// get theme
export const get_theme_flag = async () => {
  const response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/get-user-theme-mode")
  return response.data;
}
// get unread notifications
export const get_user_unread_notifications = async (id:number|undefined) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/unread-notifications");
  return response.data;
}
// get all notifications
export const get_user_all_notifications = async () => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/get-all-notifications");
  return response.data;
}
// get all notifications
export const notification_status_change = async (notification_id:number|undefined) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/read-notification/"+notification_id);
  return response.data;
}
// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post(LOGIN_URL, {
    email,
    password,
  })
  return response?.data
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  // Check if 'kt-auth-react-v' exists in localStorage
  const authValue = localStorage.getItem('kt-auth-react-v');

  if (authValue) {
    // Only make the request if the key exists
    return axios.post<UserModel>(
      `${secureLocalStorage.getItem("baseURL")}/api/verify-token`, 
      { access_token: token }
    );
  } else {
    // Handle the case where the key doesn't exist
    console.warn("No valid auth key found in localStorage.");
    return Promise.reject("Authorization key not found.");
  }
}
export async function getOrganisationMedia() {
  let baseURL:any= secureLocalStorage.getItem("baseURL");
  // Wait for baseURL if not set
  if (!baseURL) {
    console.warn("baseURL not set yet. Waiting...");
    baseURL = await waitForBaseURL();
  }
  return axios.get(`${baseURL}/api/get-logos-images`);
}

function waitForBaseURL() {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const url = secureLocalStorage.getItem("baseURL");
      if (url) {
        clearInterval(interval);
        resolve(url);
      }
    }, 100); // Check every 100ms
  });
}
// Sign Out
export function signout(access_token: string) {
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
    return axios.post(`${secureLocalStorage.getItem("baseURL")}/api/logout`, {}, { headers });
}