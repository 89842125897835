import axios from "axios";
import secureLocalStorage from "react-secure-storage";
// Add New Department
export const add_department= async (values) => {
    let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/departments", values);
    return response.data;
}
// List of Department
export const get_all_department = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/department");
    return response.data;
  }
  // List Active Department
export const get_active_department = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/department/"+1);
  return response.data;
}
// Status Filter Departments
export const get_all_filters_status_departments=async (status) => {
  const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/department/'+status);
    return response.data;
};
// Delete Department 
  export const delete_department= async (id:number) => {
    let response = await axios.delete(secureLocalStorage.getItem("baseURL") + "/api/departments/"+id);
    return response.data;
}
// Update Department
export const update_department = async (id:number,name:string,description:string,status:number) => {
    let response = await axios.patch(secureLocalStorage.getItem("baseURL") + "/api/departments/" + id, {
      name:name,
      description:description,
      status:status,
    });
    return response.data;
  }
  // Get Single Department Logs by ID
export const get_single_department_log = async (id: number) => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/department-activity-log/" + id);
  return response.data;
};
// Get All Department Logs
export const get_all_departments_logs = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/department-activity-logs");
  return response.data;
};
// change status 
export const change_active_status_department = async (id:number) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/update-status/" + id, {
    model_name:"department",
  });
  return response.data;  
}