import axios from "axios";
import secureLocalStorage from "react-secure-storage";

// get All Purchase orders
export const get_all_purchase_orders = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/purchase-order");
    return response.data;
}
// get single Purchase orders
export const get_single_purchase_order = async (id:number) => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/purchase-order/"+id);
    return response.data;
}
// Delete Purchase order
export const delete_purchase_order = async (id: number) => {
    let response = await axios.delete(secureLocalStorage.getItem("baseURL") + "/api/purchase-order/" + id);
    return response.data;
}
// status of purchase order
export const purchase_order_status = async (id: number, status: string) => {
    let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/change-status-of-purchase-order/" + id ,{
        status: status
    });
    return response.data;
}
// get All Approved Vendors
export const get_all_approved_vendors = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/approved-vendors");
    return response.data;
}
// get All Product Against Vendors
export const get_all_products_against_vendors = async (id: any) => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/quotation-products-against-vendor/" + id);
    return response.data;
}
// Add PO
export const add_purchase_order = async (formData: object) => {
    const response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/purchase-order", formData, {
    });
    return response.data;
};
// Update PO
export const update_purchase_order = async (id:number,purchaseData:object) => {
    const response = await axios.patch(
        `${secureLocalStorage.getItem("baseURL")}/api/purchase-order/${id}`,
        purchaseData
    );
    return response.data;
};
// Get Single PO Logs by ID
export const get_single_purchase_order_logs = async (id: number) => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/purchase-order-activity-logs/" + id);
    return response.data;
  };
  // Get All Product Logs
  export const get_all_purchase_order_logs = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/purchase-order-activity-logs");
    return response.data;
  };
    //Filters on PO Date
    export const get_all_filters_item =async (fromDate, toDate,startPrice,endPrice,supplier,status) => {
        const response = await axios.post(secureLocalStorage.getItem("baseURL") + '/api/filter-purchase-order', {
            from_date: fromDate,
            to_date: toDate,
            starting_price:startPrice,
            ending_price:endPrice,
            supplier_id:supplier,
            status:status
          });
      
          return response.data;
      };