import axios from "axios";
import secureLocalStorage from "react-secure-storage";
// List Of Categories
export const get_all_categories = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/categories");
  return response.data;
}
// Active Categories
export const get_all_active_categories=async () => {
  const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/categories/'+1);
    return response.data;
};
// Status Filter Categories
export const get_all_filters_status_categories=async (status) => {
  const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/categories/'+status);
    return response.data;
};
// Add_Category API
export const add_category = async (values:object) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/catagory",values);
  return response.data;
}
// Delete CategoryApi
export const delete_category = async (id:number) => {
  let response = await axios.delete(secureLocalStorage.getItem("baseURL") + "/api/catagory/"+id);
  return response.data;
}
// Update CategoryApi
export const update_category = async (id:number, title:string, status: number) => {
  let response = await axios.patch(secureLocalStorage.getItem("baseURL") + "/api/catagory/" + id, {
    title: title,
    status: status
  });
  return response.data;
}
// Get Single Category Logs by ID
export const get_category_logs = async (id: number) => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/category-activity-log/" + id);
  return response.data;
};
// Get All Categories Logs
export const get_all_categories_logs = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/category-activity-logs");
  return response.data;
};
// change status 
export const change_active_status_category = async (id:number) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/update-status/" + id, {
    model_name:"catagory",
  });
  return response.data;  
}