/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Requisition } from '../../../../app/pages/requisitions/requisitions/components/RequisitionsList'
import { useQuery } from 'react-query'
import { get_quotation_assigned_me } from '../../../../app/pages/dashboard/api/DashboardApis'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { get_requisitions_status } from '../../../../app/pages/requisitions/requisitions/api/requisitionsApi'
import { formatDate } from '../../../../app/helper-functions/general_settings_helper'

type Props = {
  className: string
}

const TablesWidget15: React.FC<Props> = ({ className }) => {
  const [requisitionsList, setRequisitionsList] = useState<Requisition[]>([])  
  const [selectedId, setSelectedId] = useState(0);
  const [statusFlag, setStatusFlag] = useState('');
  const navigate = useNavigate();
  const { data: requisitionsData, refetch } = useQuery('requisitionsAssignedMe', get_quotation_assigned_me, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (requisitionsData) {
      setRequisitionsList(requisitionsData.data);
    };
  }, [requisitionsData]);
  const { data: statusData } = useQuery(
    ['RequisitionStatus', selectedId, statusFlag],
    () => get_requisitions_status(selectedId, statusFlag),
    {
      enabled: !!selectedId && !!statusFlag, // Ensure both values are present
      refetchOnWindowFocus: false,
      onSuccess: () => {
        refetch();
      },
    }
  );
  const handleStatusAccepted = (id: number) => {
    setSelectedId(id);
    setStatusFlag('Accepted');
    toast.success('Status updated to Accepted', {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const handleStatusRejected = (id: number) => {
    setSelectedId(id);
    setStatusFlag('Rejected')
    toast.success('Status updated to Rejected', {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const handleViewQuotation = (requisition: any) => {
    navigate("/quotations/view-quotation", { state: { id: requisition.id } });
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Quotations Assigned to me</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{requisitionsList.length} requisitions</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-0'>
                <th className='min-w-150px'></th>
                <th className='min-w-140px'></th>
                <th className='min-w-120px'></th>
                <th className='min-w-100px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {requisitionsList?.length > 0 ? (
                <>
                  {requisitionsList?.map((requisition, index) => (

                    <tr key={index}>
                      <td>
                        <a className='text-dark fw-bold mb-1 fs-6'>
                          {requisition.title}
                        </a>
                      <span className='text-muted fw-semibold d-block'>{formatDate(requisition.due_date, "DD/MM/YYYY")}</span>
                      </td>
                      <td className='text-end text-muted fw-semibold'>{requisition.requisition_type_title}</td>
                      <td className='text-end'>
                        <span className='badge badge-light-success'>{requisition.status}</span>
                      </td>
                         <td>
                                                 <div className='d-flex justify-content-end flex-shrink-0'>
                                                   <Button
                                                     className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                                     onClick={() => handleViewQuotation(requisition)}
                                                   >
                                                     <KTIcon iconName='eye' className='fs-3' />
                                                   </Button>
                                                 </div>
                                               </td>
                    </tr>
                  ))}
                </>

              ) : (
                <div>No Requisitions were assigned to you</div>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget15 }
