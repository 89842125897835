/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl, } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { useMutation, useQuery } from 'react-query'
import { get_user_unread_notifications, notification_status_change } from '../../../../app/modules/auth/core/_requests'
import { TimeAgo } from '../../../../app/helper-functions/TimeAgo'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers';
type Notifications = {
  id: number;
  notification_title: string | undefined;
  notification_description: number | undefined;
  notification_type: string | undefined;
  updated_by: string | undefined;
  created_at: string;
};
function HeaderNotificationsMenu() {
  const [unreadNotificationsList, setUnreadNotificationsList] = useState<Notifications[]>([]);
  const { auth } = useAuth()
  const navigate = useNavigate();
    const { data: notificationsData } = useQuery(
    ['Notifications', auth?.user?.id],
    () => get_user_unread_notifications(auth?.user?.id),
    {
      refetchInterval: 60000, // Set the polling interval to 1 minute
    }
  );
  useEffect(() => {
    if (notificationsData) {
      setUnreadNotificationsList(notificationsData.data as Notifications[]);
    };
  }, [notificationsData]);
  const handleStatusChange = useMutation((id: number,) => {
    return notification_status_change(id).then((response) => {
    })
      .then(() => {
        return
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });

  const parseDescription = (description) => {
    try {
      const parsedDescription = JSON.parse(description);
      return parsedDescription.description;
    } catch (error) {
      return
    }
  };


  const handleViewRequisition = (description: any) => {
    const parsedDescription = JSON.parse(description);
    navigate("/requisitions/view-requisition", { state: { id: parsedDescription.requisition_id } });
  }
  const handleViewQuotation = (description: any) => {
    const parsedDescription = JSON.parse(description);
    navigate("/quotations/view-quotation", { state: { id: parsedDescription.quotation_id } });
  };
  const handleViewStockOut = (description: any) => {
    const parsedDescription = JSON.parse(description);
    navigate("/stocks/view-stock-out", { state: { id: parsedDescription.stockout_id } });
  }
  const handleViewStockIn = (description: any) => {
    const parsedDescription = JSON.parse(description);
    navigate("/stocks/view-stock-out", { state: { id: parsedDescription.stockout_id } });
  }
  // For close Notification menu
  const handleFakeOutsideClick = () => {
    document.body.click();
  };  

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
        >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6 d-flex align-items-center'>
      Notifications
      <span className='fs-8 opacity-75 ps-3'>{unreadNotificationsList.length}</span>
      <button            
       onClick={handleFakeOutsideClick} 
 className='btn btn-icon btn-sm btn-active-light-primary ms-auto'>
        <KTSVG
          path='/media/icons/duotune/arrows/arr061.svg'
          className='svg-icon svg-icon-2x'
        />
      </button>
    </h3>
          {/* commenting for future use  */}
          {/* <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_1'
          >
            Alerts
          </a>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 '
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_2'
          >
            Updates
          </a>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_3'
          >
            Logs
          </a>
        </li>
      </ul> */}
        </div>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div className='scroll-y mh-325px my-5 px-8'>
              {unreadNotificationsList.map((notification, index) => (
                <div key={`notification${index}`} className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      {notification.notification_type === 'Requisition' && (
                        <span className={clsx('symbol-label', `bg-light-primary`)}>
                          {' '}
                          <KTIcon iconName="abstract-25" className={`fs-2 text-primary`} />
                        </span>
                      )}
                      {notification.notification_type === 'Quotation' && (
                        <span className={clsx('symbol-label', `bg-light-warning`)}>
                          {' '}
                          <KTIcon iconName="abstract-28" className={`fs-2 text-warning`} />
                        </span>
                      )}
                      {notification.notification_type === 'Stockout' && (
                        <span className={clsx('symbol-label', `bg-light-danger`)}>
                          {' '}
                          <KTIcon iconName="package" className={`fs-2 text-danger`} />
                        </span>
                      )}
                    </div>
                    <div className='mb-0 me-2'>
                      {notification.notification_type === 'Requisition' && (
                        <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                          onClick={() => {
                            handleViewRequisition(notification.notification_description)
                            if (notification && notification.id) {
                              handleStatusChange.mutate(notification.id);
                            }
                          }
                          }>
                          {notification.notification_title}
                        </span>
                      )}
                      {notification.notification_type === 'Quotation' && (
                        <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer' 
                          onClick={() => {
                            handleViewQuotation(notification.notification_description)
                            if (notification && notification.id) {
                              handleStatusChange.mutate(notification.id);
                            }
                          }
                          }>
                          {notification.notification_title}
                        </span>
                      )}
                      {notification.notification_type === 'Stockout' && (
                        <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                          onClick={() => {
                            handleViewStockOut(notification.notification_description)
                            if (notification && notification.id) {
                              handleStatusChange.mutate(notification.id);
                            }
                          }
                          }>
                          {notification.notification_title}
                        </span>
                      )}
                      {notification.notification_type === 'Stockin' && (
                        <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                          onClick={() => {
                            handleViewStockIn(notification.notification_description)
                            if (notification && notification.id) {
                              handleStatusChange.mutate(notification.id);
                            }
                          }
                          }>
                          {notification.notification_title}
                        </span>
                      )}
                      <div className='text-gray-400 fs-7'>{parseDescription(notification.notification_description)}</div>
                    </div>
                  </div>
                  <span className='badge badge-light fs-8'>{TimeAgo(notification.created_at)}</span>
                </div>
              ))}
            </div>
            <div className='py-3 text-center border-top'>
              <Link
                to='all-notifications'
                className='btn btn-color-gray-600 btn-active-color-primary'
              >
                View All <KTIcon iconName='arrow-right' className='fs-5' />
              </Link>
            </div>
          </div>

          {/* commenting for future use  */}
          {/* <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div>
      </div> */}
        </div>
      </div>
    </>
  )
}

export { HeaderNotificationsMenu }
