import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { mediaPath } from '../../../helper-functions/ImageStoragePath';
import { useQuery } from 'react-query';
import { get_single_product } from '../api/productsApi';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

const imagePath = `${mediaPath()}/rejected.png`;
type Product = {
    id: number | undefined;
    name: string | undefined;
    product_type:string | undefined;
    price: number | undefined;
    categories_id: number | undefined;
    category_title: string | undefined;
    sub_categories_id: number | undefined;
    sub_category_title: string | undefined;
    short_description: string | undefined;
    long_description: string | undefined;
    picture: string | undefined;
    remaining_stock_quantity: number | undefined;
    status: number | undefined;
    updated_by: string | undefined;
    updated_at: string;
};
function ViewProduct() {
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [singleProduct, setSingleProduct] = useState<Product | null>();
    const location = useLocation();
    const navigate = useNavigate();
    const id = (location.state as { id: number }).id;
    const { data } = useQuery(['ViewQuotation', id], () =>
        setSelectedId(id), {refetchOnWindowFocus: false}
    );
    const { data: singleProductData, isLoading: isSingleProductLoading } = useQuery(
        ['singleQuotation', selectedId],
        () => get_single_product(selectedId!),
        {
            enabled: !!selectedId,
            refetchOnWindowFocus: false,
        }
    );
    useEffect(() => {
        if (singleProductData) {
            setSingleProduct(singleProductData.data as Product);
        }
    }, [singleProductData]);
 
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>View Product</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/products/products-list')}
                    >
                        List of Products
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {singleProduct ? (
                        <>
                            {/* ... Edit Form ... */}
                            <Form>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Category</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.category_title}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Subcategory</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.sub_category_title}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        Product Type
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        {singleProduct.product_type === 'consumable' ? (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Consumable
                                            </label>
                                        ) : (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Asset
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Product Name
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.name}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Product Picture</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label>
                                            {singleProduct.picture ? (
                                                <img
                                                    src={singleProduct.picture}
                                                    width={300}
                                                    height={400}
                                                    alt='Product'
                                                    className='symbol'
                                                />
                                            ) : (
                                                <span className='px-7 text-danger'>No Image</span>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Short Description
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.short_description}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        Long Description
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.long_description}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Price ({getSettingValue('currency_symbol')})</label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.price}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Remaining Quantity</label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleProduct.remaining_stock_quantity}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span >Status</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        {singleProduct.status === 1 ? (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Active
                                            </label>
                                        ) : (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Inactive
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ViewProduct;