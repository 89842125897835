import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const get_users_requisitions = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/get-created-requisition");
    return response.data;
}

export const get_assigned_requisitions = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/requisitions-assignees");
    return response.data;
}
export const get_assigned_quotations = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/quotation-assignees");
    return response.data;
}
export const get_quotation_assigned_me = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/get-quotation-assigned-me");
    return response.data;
}