import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import { get_all_vendors } from '../../vendors/api/vendorsApi';
import { get_all_active_products } from '../../products/api/productsApi';
import AddNewProduct from '../../purchases/components/AddNewProduct';
import Flatpickr from 'react-flatpickr';
import { add_stock_in } from '../api/stocksApi';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
import Select from 'react-select';

interface ProductType {
  id: number;
  product_id: number;
  name: string;
  product_name: string;
  product_type: string;
  discountedPriceProduct: number;
  discountedPrice: number;
  quantity: number;
  rate: number;
  quotation_detail_price: object;
  supplier_name: string;
  created_at: string;
  updated_at: string;
}
const StockSchema = Yup.object().shape({ date: Yup.date().required('Please Select a Date'), });
const initialValues: any = {
  supplier_name: "",
  date: "",
};
function AddStockIn() {
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [allProduct, setAllProduct] = useState<any[]>([]);
  const [selectedStockIn, setSelectedStockIn] = useState<ProductType[]>([]);
  const [priceProduct, setPriceProduct] = useState(1);
  const [quantityProduct, setQuantityProduct] = useState(1);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const navigate = useNavigate();
  const [dateState, setDateState] = useState<any>({
    date1: new Date(),
    date2: null
  });
  const { data: allVendors } = useQuery('vendors', get_all_vendors, {refetchOnWindowFocus: false});
  const { data: allProducts } = useQuery('allProductSystem', get_all_active_products, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (allVendors && Array.isArray(allVendors.data)) {
      setSelectedVendor(allVendors.data as any[]);
    } else {
      setSelectedVendor([]);
    }
  }, [selectedVendor]);
  useEffect(() => {
    if (allProducts && Array.isArray(allProducts.data)) {
      setAllProduct(allProducts.data as any[]);
    } else {
      setAllProduct([]);
    }
  }, [allProducts]);
  useEffect(() => {
    // Initialize filteredProducts with all products
    setFilteredProducts(Array.isArray(allProduct) ? allProduct : []);
  }, [allProduct]);
  useEffect(() => {
    // Update totalQuantity and totalPrice whenever selectedStockIn changes
    let updatedTotalQuantity = 0;
    let updatedTotalPrice = 0;

    selectedStockIn.forEach((product) => {
      const priceProduct = product.quantity * product.rate;
      updatedTotalQuantity += product.quantity;
      updatedTotalPrice += priceProduct;
    });

    setTotalQuantity(updatedTotalQuantity);
    setTotalPrice(updatedTotalPrice);
  }, [selectedStockIn]);
  const handleProceedToPurchase = async () => {
    await formik.validateForm();
    try {
      setLoading(true);
      const { supplier_name, date } = formik.values;
      const stock_products = selectedStockIn.map((product) => {
        const { id, product_name, quantity, rate, ...otherProductDetails } = product;
        const priceProduct = product.quantity * product.rate;
        return {
          id,
          product_name,
          quantity,
          rate: product.rate || 0,
          price: priceProduct || 0,
          final_price: priceProduct || 0,
          ...otherProductDetails,
        };
      });
      const supplier_id = supplier_name;
      const date_time = date ? new Date(date).toISOString().split('T')[0] : null;
      const purchaseData = {
        supplier_id,
        date_time,
        description: formik.values.description,
        stock_products,
        // ...other formik values if needed
      };
      const response = await add_stock_in(purchaseData);
      navigate("/stocks/stock-in-list");
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
    } finally {
      setLoading(false);
    };
  };
  const formik = useFormik<any>({
    validationSchema: StockSchema,
    initialValues,
    onSubmit: handleProceedToPurchase,
  });
  // {Add Product Code}
  // Handle Product in Modal
  const handleProductChange = (e) => {
    const selectedProductId = e.value;
    setSelectedProduct(selectedProductId);
    // Find the selected product details
    const selectedProductDetails = allProduct.find((product) => product.id === parseInt(selectedProductId));
    // Update the state with selected product details
    if (selectedProductDetails) {
      setPriceProduct(selectedProductDetails.price || 1);
      setQuantityProduct(selectedProductDetails.quantity || 1);
      // You may set other related fields here if needed
    } else {
      // // Handle the case when the selected product details are not found
      setSelectedProduct(null);
      setPriceProduct(1);
    }
  };
  const handleAddProduct = () => {
    // Check if a product is selected
    if (selectedProduct) {
      // Find the selected product details directly using the selectedProduct object
      const selectedProductDetails = allProduct.find(
        (product) => product.id === parseInt(selectedProduct)
      );

      // Check if selectedProductDetails is defined
      if (selectedProductDetails) {
        // Check if the product already exists in the selectedStockIn array
        const productExists = selectedStockIn.some(
          (product) => product.product_id === selectedProductDetails.id
        );

        if (productExists) {
          // If the product already exists, show a toast and return
          toast.warning('Product Already Exist', {
            position: 'top-right',
            autoClose: 2000,
          });
          return;
        }

        // Create a new product object with details
        const newProduct = {
          product_id: selectedProductDetails.id,
          product_name: selectedProductDetails.name || '',
          quantity: quantityProduct || 1, // Assuming quantity is a state variable
          rate: priceProduct || 0, // Assuming rate is a state variable
        };

        // Update the state with the new product
        setSelectedStockIn((prevProducts: any) => {
          // If prevProducts is not an array (undefined or null), initialize it as an empty array
          const updatedProducts = Array.isArray(prevProducts)
            ? [...prevProducts, newProduct]
            : [newProduct];
          return updatedProducts;
        });
        // Show a success toast message
        toast.success('Product Added Successfully.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }
  };

  // Table Code
  const handleQuantityChange = (productId: number, value: string) => {
    if (value === '' || !isNaN(Number(value))) {
      setSelectedStockIn((prevProducts: any) =>
        prevProducts.map((product) =>
          product.product_id === productId ? { ...product, quantity: value === '' ? '' : Number(value) } : product
        )
      );
    }
  };


  const handlePriceChange = (productId, value) => {
    const numericValue = parseFloat(value);
    const updatedProducts: any = selectedStockIn.map((product) =>
      product.product_id === productId ? { ...product, rate: isNaN(numericValue) ? '' : numericValue } : product
    );
    setSelectedStockIn(updatedProducts);
  };


  const handleRemoveProduct = (productId) => {
    // Implement the logic to remove the product from state
    setSelectedStockIn((prevProducts) =>
      prevProducts.filter((product) => product.product_id !== productId)
    );
  };
  // Add an event listener for keydown events
  const handleKeyDown = (event, productId) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      const targetTagName = event.target.tagName.toLowerCase();

      // Check if the event target is a button
      if (targetTagName === 'button') {
        event.preventDefault(); // Prevent the default form submission behavior
        handleRemoveProduct(productId);
      }
    }
  };
  return (
    <div className='card card-custom shadow'>
      <div
        className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Stock in</h3>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className="card-body">
          <Form>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Date</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Flatpickr
                  value={dateState.date2}
                  onChange={([date2]) => {
                    setDateState({ date2 });
                    formik.setFieldValue('date', date2 ? date2.toISOString() : null);

                    if (!date2) {
                      formik.setFieldError('date', 'Due Date is required');
                    } else {
                      formik.setFieldError('date', '');
                    }
                  }}
                  className='form-control form-control-solid'
                  placeholder='Date'
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
              </div>

            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Vendor(Optional)</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('supplier_name')}
                >
                  <option value=''>Select a Vendor...</option>
                  {Array.isArray(selectedVendor) && selectedVendor.length > 0 ? (
                    selectedVendor.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.supplier_name}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No Vendors Available</option>
                  )}
                </select>
              </div>
            </div>
          </Form>
          <div
            className='card-header border-0 cursor-pointer'
          >

            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add New Product"
              className='btn btn-sm btn-light-dark m-4'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add New Product
            </button>
            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Add Product"
              className="btn btn-primary m-4"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_1"

            >
              Add Product
            </button>
          </div>
        </div>

      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Form>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-100px required'>Product</th>
                  <th className='min-w-100px required'>Quantity</th>
                  <th className='min-w-140px'>Rate ({getSettingValue('currency_symbol')})</th>
                  <th className='min-w-120px'>Price ({getSettingValue('currency_symbol')})</th>
                  <th className='min-w-150px'>Final Price ({getSettingValue('currency_symbol')})</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* Inside the table body */}
                {selectedStockIn.length > 0 &&
                  selectedStockIn.map((product: any) => {
                    const priceproduct = product?.quantity * product.rate;
                    return (
                      <tr key={product.id}>
                        <td>
                          <input
                            className='card'
                            type="text"
                            {...formik.getFieldProps(`product_name.${product.product_id}`)}
                            readOnly  // Make the input read-only to display the calculated value
                            value={product.product_name}
                          />
                        </td>
                        <td>
                          <input
                            className='card'
                            type="text"
                            placeholder='Quantity'
                            {...formik.getFieldProps(`quantity.${product.product_id}`)}
                            onChange={(e) => handleQuantityChange(product.product_id, e.target.value)}
                            value={product.quantity}
                          />

                        </td>

                        <td>
                          <input
                            className='card'
                            type="text"
                            placeholder='Rate'
                            {...formik.getFieldProps(`rate.${product.product_id}`)}
                            value={
                              product.rate
                            }
                            onChange={(e) => handlePriceChange(product.product_id, e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            className='card'
                            type="number"
                            readOnly  // Make the input read-only to display the calculated value
                            value={priceproduct.toFixed(2)}
                          />
                        </td>
                        <td>
                          <input
                            className='card'
                            type="number"
                            readOnly  // Make the input read-only to display the calculated value
                            value={priceproduct.toFixed(2)}
                          />
                        </td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* Add a Remove button with an onClick event handler */}
                            <button
                              type='button'
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete Product"
                              className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                              onClick={() => handleRemoveProduct(product.product_id)}
                              onKeyDown={(e) => handleKeyDown(e, product.product_id)}
                              tabIndex={0}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    Total Quantity ({getSettingValue('currency_symbol')}):
                    <input
                      className='card'
                      type="text"
                      placeholder='Total Discount'
                      value={totalQuantity}
                    />
                  </td>
                  <td colSpan={2}>
                    Total Price ({getSettingValue('currency_symbol')}):
                    <input
                      className='card'
                      type="number"
                      readOnly
                      value={totalPrice.toFixed(2)}
                    />
                  </td>
                  <td className='min-w-140px'></td>
                  <td colSpan={2}></td>
                  <td colSpan={1}></td>
                </tr>
                {/* Additional fields go here */}
              </tfoot>

            </table>
          </Form>
        </div>

      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          data-toggle='tooltip'
          data-placement='bottom'
          title='Stock In'
          type='button'
          className='btn btn-primary'
          disabled={loading}
          onClick={handleProceedToPurchase}
        >
          {!loading && 'Stock In'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* Add Product */}
      {/* Add Product */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Product</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Form>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product</label>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      placeholder="Select "
                      options={filteredProducts.map((product) => ({
                        value: product.id,
                        label: product.name,
                      }))}
                      className="basic-multi-select form-select-lg p-2 bg-light text-black"
                      classNamePrefix="select border-light fw-bold"
                      onChange={handleProductChange}
                    />
                  </div>
                </div>
                {selectedProduct && (
                  <div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Quantity'
                          value={quantityProduct}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantityProduct(Number(e.target.value))}
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Rate ({getSettingValue('currency_symbol')})</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Rate'
                          value={priceProduct}
                          onChange={(e: any) => setPriceProduct(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Price ({getSettingValue('currency_symbol')})</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          readOnly
                          value={(quantityProduct * priceProduct).toFixed(2)}
                        />
                      </div>
                    </div>
                  </div>)}
              </Form>
            </div>
            <div className='modal-footer'>
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                title="Close"
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                title="Add Product"
                className='btn btn-sm btn-light-dark'
                onClick={() => {
                  handleAddProduct();
                }}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Product
              </button>
            </div>

          </div>
        </div>
      </div>
      {/* New Product Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add New Product</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <AddNewProduct />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddStockIn