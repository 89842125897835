import axios from "axios";
import secureLocalStorage from "react-secure-storage";

// Add New requisition type
export const add_requisition_type = async (values: object) => {
    let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/requisition-type", values);
    return response.data;  
}

// List Of Type Requisition
export const get_all_requisition_type = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/requisition-type");
  return response.data;
}
//delete requisition type
export const update_requisition_type = async (id:number, title:string, status: number) => {
  let response = await axios.patch(secureLocalStorage.getItem("baseURL") + "/api/requisition-type/" + id, {
    title: title,
    status: status
  });
  return response.data;
}
// Delete Requisition Type
export const delete_requisition_type = async (id: number) => {
  let response = await axios.delete(secureLocalStorage.getItem("baseURL") + "/api/requisition-type/"+id);
  return response.data;
}
// Get Single Requisition Type Logs by ID
export const get_requisition_type_logs = async (id: number) => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/requisition-type-activity-log/" + id);
  return response.data;
};
// Get All Requisition Type Logs
export const get_all_requisition_types_logs = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/requisition-type-activity-logs");
  return response.data;
};

