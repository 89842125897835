import { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom'
import { addRequisitionType, addRequisitionTypeInitValues as initialValues } from '../../../../modules/accounts/components/settings/SettingsModel';
import { toast } from 'react-toastify';
import { add_requisition_type } from '../api/requisitionsTypeApi';

const requisitionTypeSchema = Yup.object().shape({
    typeTitle: Yup.string().required("Requisition Type is required"),
})

function AddRequisitionType() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const mutation = useMutation(add_requisition_type, {
        onSettled: (data) => {
            toast.success("The requisition type has been created successfully", {
                position: "top-right",
                autoClose: 3000,
            });
            navigate('/requisition-type/requisition-type-list')
            setLoading(false)
            queryClient.invalidateQueries('requisitionType'); // Optional: Invalidate the vendors query to refetch data
        },
        onError: (error: any) => {
            toast.error(error.message, {
              position: 'top-right',
              autoClose: 3000,
            })
          },
    });
    const formik = useFormik<addRequisitionType>({
        initialValues,
        validationSchema: requisitionTypeSchema,
        onSubmit: (values, action) => {
            setLoading(true)
            mutation.mutate({
                "title": values.typeTitle,
                "status": values.status,
            });
            action.resetForm()
        },
    }
    )
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Add Requisition Type</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Requisition Type</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder="Requisition Type"
                                    {...formik.getFieldProps('typeTitle')}
                                />
                                {formik.touched.typeTitle && formik.errors.typeTitle && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.typeTitle}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Status</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('status')}
                                >
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button data-toggle="tooltip" data-placement="bottom" title="Add New Requisition Type" type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Add Requisition Type'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddRequisitionType
