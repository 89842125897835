import axios from "axios";
import { config_multipart } from "../../api-helper/api-header-config";
import secureLocalStorage from "react-secure-storage";
const CENTRAL_baseURL = process.env.REACT_APP_CENTRAL_API_URL;
// Password change
export const changePassword = async (passwordData: any) => {
    const response = await axios.post(`${secureLocalStorage.getItem("baseURL")}/api/change-password`, passwordData);
    return response.data;
  };
  // Get Single User Profile
export const get_single_user_profile = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/get-user-profile");
  return response.data;
}
// Update User Profile
export const update_user_profile = async (formData:object) => {
  const response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/update-user-profile", formData,config_multipart)
return response.data;
}
export const update_user_profile_central = async (formData:object) => {
  const response = await axios.post(CENTRAL_baseURL + "/api/update-user-profile", formData,config_multipart)
return response.data;
}