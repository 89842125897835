import axios from "axios";
import secureLocalStorage from "react-secure-storage";

// get All products
export const get_all_products = async () => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/filter-products");
  return response.data;
}
// get All active products
export const get_all_active_products = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/products/1");
  return response.data;
}
// Status Filter Products
export const get_all_filters_status_products=async (status) => {
  const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/products/'+status);
    return response.data;
};
//signle product
export const get_single_product = async (id: number) => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/product/" + id);
  return response.data;
}
// Edit Product
export const update_product = async (id: number, formData: object) => {
  try {
    const response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/product-update/" + id, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Add Product
export const add_product = async (formData: object) => {
  try {
    const response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/product", formData, {
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const delete_product = async (id: number) => {
  let response = await axios.delete(secureLocalStorage.getItem("baseURL") + "/api/product/" + id);
  return response.data;
};
// Get Filtered Product by ID
export const get_filtered_products = async (category, subcategory,status) => {
  const response = await axios.post(secureLocalStorage.getItem("baseURL") + '/api/filter-products', {
    category_id:category,
    sub_category_id:subcategory,
    status:status
  });
  return response.data;
};
// Get Single Product Logs by ID
export const get_product_logs = async (id: number) => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/product-activity-log/" + id);
  return response.data;
};
// Get All Product Logs
export const get_all_products_logs = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/product-activity-logs");
  return response.data;
};
// change status 
export const change_active_status_product = async (id:number) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/update-status/" + id, {
    model_name:"product",
  });
  return response.data;  
}