import { StringSupportOption } from "prettier"

//Interface for vendor
export interface addVendor {
  type: string
  vName: string
  vEmail: string
  vMobile: string
  vTelephone: string
  address1: string
  address2: string
  town: string
  city: string
  postcode: string
  status:number
};
//Interface for Department
export interface addDepartment {
  name: string;
  description: string;
  status:string;
};
//Interface for Department
export interface addDepartmentList {
  id:number;
  name: string;
  description: string;
  status:number;
  user_count: number;
  created_at:string;
  updated_at:string;
  updated_by_name:string;
  updated_by:string;
};
export const addDepartmentInitValues: addDepartment = {
  name:"",
  description:"",
  status:"",
};
export const addVendorInitValues: addVendor = {
  type: '',
  vName: '',
  vEmail: '',
  vMobile: '',
  vTelephone: '',
  address1: '',
  address2: '',
  town: '',
  city: '',
  postcode: '',
  status:1
};
// Interface Add Category
export interface addCategories {
  categoryName: string
  status:number,
};
export const initialValues: addCategories = {
  categoryName: '',
  status:1,
};
//Interface for subcategories
export interface addSubcategories {
  categoryTitle: string
  sTitle: string
  status: number
};
export const addSubcategoriesInitValues: addSubcategories = {
  categoryTitle: '',
  sTitle: '',
  status: 1
};
//Interface for product
export interface addProduct {
  product_type:string;
  type:string;
  categoryTitle: string;
  subcategoryTitle: string;
  pName: string;
  shortDescription: string;
  longDescription: string;
  price: string;
  status: any;
};
export const addProductsInitValues: addProduct = {
  product_type:"",
  type:"",
  categoryTitle: '',
  subcategoryTitle: '',
  pName: '',
  shortDescription: '',
  longDescription: '',
  price: '',
  status: 1,
};
export interface addUser {
  title: string;
  departmentname: string;
  firstname: string;
  role: string;
  middlename: string;
  lastname: string;
  telephone: string;
  email: string;
  password: string;
  mobile: string;
  picture: string;
  status: any,
}
export interface allPoType{
  vendor_name:string;
  vendor_id:number;
  supplier_name:string;
  rate:number;
  po_date:string;
  selectedProduct:string;
  quantity:number;
  discounted_price:string;
  price:string;
  final_price:string;
  grandTotal:string;
}

//Interface for subcategories
export interface addRequisitionType {
  typeTitle: string
  status: number
};
//Interface for Quotation
export interface addQuotationType {
  typeTitle: string
};

export const addRequisitionTypeInitValues: addRequisitionType = {
  typeTitle: '',
  status: 1,
};
export const addQuotationTypeInitValues: addQuotationType = {
  typeTitle: '',
};
export interface addRequisition {
  requisitionTitle: string;
  requisitionDescription: string;
  dueDate: string;
  requisitionType: string;
  products: { product: any; quantity: number }[];
  requisitionAssignees: { value: string }[];
  requisition_documents: { document_title: string; document_description: string, document_path: string }[];
};

export const addRequisitionsInitValues: addRequisition = {
  requisitionTitle: '',
  requisitionDescription: '',
  dueDate: '',
  requisitionType: '',
  products: [{ product:-1, quantity: 1 }],
  requisitionAssignees: [],
  requisition_documents: []
};
export interface addQuotation {
  title: string;
  description: string;
  due_date: string;
  requisition: string;
  quotations: {
    id: number;
    product: string;
    quantity: number;
    quotationDetailPrices: {
      supplier?:string|undefined;
      price?:number|null;
      approved?: number;
    }[];
  }[];
  quotationAssignees: { value: string|null }[];
  quotation_documents: { supplier_id: string, document_title: string; document_description: string, document_path: File }[];
}
export const addQuotationsInitValues: addQuotation = {
  title: "",
  description: "",
  due_date: "",
  requisition: "",
  quotations: [
  ],
  quotationAssignees: [],
  quotation_documents: [],
};

export interface addStockOut {
  date: string;
  description: string;
  department: number;
  user: string;
  requisition: string;
  products: { product: number; quantity: number; remainingQuantity: number }[];
};

export const addStockOutInitValues: addStockOut = {
  date: '',
  description: '',
  department: 0,
  user: '',
  requisition: '',
  products: [],
};
export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  email:"",
  title:"",
  first_name:"",
  middle_name:"",
  last_name:"",
  phone_number:"",
  telephone: "",
  picture:"",
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
export interface IProfileDetails {
  email: string
  title: string
  first_name: string
  middle_name: string
  last_name: string
  phone_number: string
  telephone: string
  picture: string | null
}
// Add Organizations Interface
export interface addOrganization {
  organisation_name: string;
  address_line_1: string;
  address_line_2: string;
  country: string;
  city: string;
  post_code: string;
  phone_1: string;
  phone_2: string;
  email: string;
  website: string;
  currency_name: string;
  currency_symbol: string;
  favicon: File | null;
  white_logo: File | null;
  colored_logo: File | null;
}
export const addOragnizationInitValues: addOrganization = {
  organisation_name: "",
  address_line_1: "",
  address_line_2: "",
  country: "",
  city: "",
  post_code: "",
  phone_1: "",
  phone_2: "",
  email: "",
  website: "",
  currency_name: "",
  currency_symbol: "",
  favicon: null,
  white_logo: null,
  colored_logo: null
};
export const country= [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao, People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco", "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia (Slovak Republic)",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe"
]