import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
type Vendor = {
    id: number;
    supplier_type: string | undefined;
    supplier_name: string | undefined;
    email: string | undefined;
    mobile_no: string | undefined;
    telephone: string | undefined;
    address_line_1: string | undefined;
    address_line_2: string | undefined;
    town: string | undefined;
    city: string | undefined;
    post_code: string | undefined;
    status: number | undefined;
    updated_by: string | undefined;
    created_at: string | undefined;
    updated_at: string | undefined;
};
function ViewVendor() {
    const [selectedVendor, setSelectedVendor] = useState<Vendor | null>();
    const location = useLocation();
    const navigate = useNavigate();
    const vendor = (location.state as { vendor: Vendor }).vendor;
    useEffect(() => {
        setSelectedVendor(vendor);
    }, [vendor]);
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>View Vendor</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Vendors"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/vendors/vendors-list')}
                    >
                        List of Vendors
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {selectedVendor ? (
                        <>
                            {/* ... Edit Form ... */}
                            <Form>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Vendor Type:</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.supplier_type}
                                        </label>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Vendor's Name</label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.supplier_name}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Vendor's Email</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.email}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Telephone</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.telephone}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Mobile</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.mobile_no}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Address Line 1</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.address_line_1}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Address Line 2</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.address_line_2}
                                        </label>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Town</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.town}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>City</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.city}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Postcode</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {selectedVendor.post_code}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span >Status</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        {selectedVendor.status === 1 ? (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Active
                                            </label>
                                        ) : (
                                            <label className='form-control form-control-lg form-control-solid'>
                                                Inactive
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default ViewVendor;