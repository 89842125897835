import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_single_quotation } from '../api/quotationsApi';
import { formatDateWithoutTime } from '../../../../helper-functions/DateFunction';
import { all_requsition_assignees } from '../../../requisitions/requisitions/api/requisitionsApi';
import { getSettingValue } from '../../../../helper-functions/general_settings_helper';
interface SingleQuotation {
    id: number | undefined;
    title: string | undefined;
    description: string | undefined;
    due_date: string;
    requisition_id: number | undefined;
    requisition_title: string | undefined;
    updated_by: string;
    updated_at: string;
    quotation_products: {
        id: number;
        quotation_id: number;
        quantity: number;
        product_id: number;
        product_name: string;
        quotation_detail_price: {
            id: number;
            supplier_id: number;
            supplier_name: string;
            price: number;
            approved: number;
            updated_by: string;
            updated_at: string;
        }[];
        updated_by: string;
        updated_at: string;
    }[];
    quotation_assignees: {
        id: number;
        user_id: number;
        user_name: string;
        updated_by: string;
        updated_at: string;
        product_id: number;
        requisition_id: number;
    }[];
};
interface User {
    id: number;
    full_name: string;
};

function ViewQuotation() {
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const [singleQuotation, setSingleQuotation] = useState<SingleQuotation | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const id = (location.state as { id: number }).id;
    const { data } = useQuery(['ViewQuotation', id], () =>
        setSelectedId(id), {refetchOnWindowFocus: false}
    );
    const { data: singleQuotationData, isLoading: isSingleQuotationLoading } = useQuery(
        ['singleQuotation', selectedId],
        () => get_single_quotation(selectedId!),
        {
            refetchOnWindowFocus: false,
            enabled: !!selectedId,
        }
    );
    useEffect(() => {
        if (singleQuotationData) {
            setSingleQuotation(singleQuotationData.data as SingleQuotation);
        }
    }, [singleQuotationData]);

    const { data: userData } = useQuery('users', all_requsition_assignees, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (userData) {
            setUsers(userData.data as User[]);
        }
    }, [userData]);
    const getAssigneeNameById = (assigneeId) => {
        const assignee = users.find((p) => p.id === assigneeId);
        return assignee ? assignee.full_name : 'Assignee not found';
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>View Quotation</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/quotations/quotations-list')}
                    >
                        List of Quotations
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {isSingleQuotationLoading ? (
                        <tr>
                            <td colSpan={6}>
                                <div className='text-center'>
                                    <div className='spinner-border' role='status'>
                                        <span className='visually-hidden'>Loading...</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ) : singleQuotation ? (
                        <>
                            <div className='form-group'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                        <span>Quotation Title</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleQuotation.title}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Description</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleQuotation.description}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                        <span>Valid Until</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {formatDateWithoutTime(singleQuotation.due_date)}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                        <span>Quotation Assignee</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleQuotation.quotation_assignees.map((assignee, index) => (
                                                <span key={assignee.id} className='badge badge-secondary mx-1'>
                                                    {getAssigneeNameById(assignee.user_id)}
                                                </span>
                                            ))}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                        <span>Requisition Title</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singleQuotation.requisition_title}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <h3 >
                                    Quotation Products:
                                </h3>
                                <div className='row mb-6'>
                                    {/* <div className='form-group col-lg-8 fv-row'> */}
                                    {singleQuotation.quotation_products.map((product, index) => (
                                        <>
                                            <div className='col-lg-4 ml-4 fw-bold fs-6'></div>
                                            <div key={index} className='form-group col-lg-8 fv-row pb-3 mb-2 border border-secondary rounded'>
                                                <div className='row'>
                                                    {/* Product Name */}
                                                    <label className='col-lg-4 col-form-label fw-bold fs-6 mt-5'> <span>Product Name</span>  </label>
                                                    <div className='col-lg-8 fv-row  mt-5'>
                                                        <label className='form-control form-control-lg form-control-solid'>
                                                            {product.product_name}
                                                        </label>
                                                    </div>
                                                    <label className='col-lg-4 col-form-label fw-bold fs-6 mt-5'> <span>Quantity</span>  </label>
                                                    <div className='col-lg-8 fv-row mt-5'>
                                                        <label className='form-control form-control-lg form-control-solid'>
                                                            {product.quantity}
                                                        </label>
                                                    </div>
                                                    {product.quotation_detail_price.map((detail, detailIndex) => (
                                                        <div key={detailIndex} className='row bg-light border border-secondary rounded pb-3 mt-3 mx-6 col-lg-11'>
                                                            <div className='d-flex justify-content-between'>
                                                            <div className='col-lg-5 fv-row  mt-3'>
                                                            <label className='col-lg-5 col-form-label fw-bold fs-6 mt-5'> <span>Vendor Name</span>  </label>
                                                                <label className='form-control form-control-lg'>
                                                                    {detail.supplier_name}
                                                                </label>
                                                            </div>
                                                            <div className='col-lg-5 fv-row mt-3'>
                                                            <label className='col-lg-5 col-form-label fw-bold fs-6 mt-5'> <span>Price ({getSettingValue('currency_symbol')})</span>  </label>
                                                                <label className='form-control form-control-lg '>
                                                                    {(Math.round(detail.price * 100) / 100).toFixed(2)}
                                                                </label>
                                                            </div>
                                                            </div>
                                                            {detail.approved === 1 && (
                                                                <div className='col-lg-3 col-form-label fw-bold fs-6 mt-3 text-primary'>
                                                                    <label >
                                                                        Recommended
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default ViewQuotation
