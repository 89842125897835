import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
export const getSettingValue = (settingKey) => {
    const value = secureLocalStorage.getItem("general_settings");
    if (Array.isArray(value)) {
        const setting = value.find(item => item.setting_key === settingKey);
        return setting ? setting.setting_value : null;
    } else {
        console.error('Data is not available in localStorage or not in the correct format (not an array)');
        return null;
    }
}
/**
 * Formats a given date string or Date object into the specified format.
 * For date strings like "2024-12-25".
 * @param {string | Date} dateInput - The input date (string or Date object).
 * @param {string} format - The desired date format (e.g., "DD/MM/YYYY").
 * @returns {string} The formatted date.
 */
export const formatDate = (dateInput, format = 'DD/MM/YYYY') => {
  if (!dateInput) {
    console.warn('Invalid date input provided to formatDate.');
    return '';
  }

  return moment(dateInput).format(format);
};

/**
 * Formats a given date-time string or Date object into the specified format.
 * For date-time strings like "2024-12-23T14:08:29.000000Z".
 * @param {string | Date} dateTimeInput - The input date-time (string or Date object).
 * @param {string} format - The desired date-time format (e.g., "DD/MM/YYYY h:mm a").
 * @returns {string} The formatted date-time.
 */
export const formatDateTime = (dateTimeInput, format = 'DD/MM/YYYY h:mm a') => {
  if (!dateTimeInput) {
    console.warn('Invalid date-time input provided to formatDateTime.');
    return '';
  }

  return moment(dateTimeInput).format(format);
};
