/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { get_single_user } from '../../../../app/pages/users/api/usersApi';
import { useQuery } from 'react-query'
import { get_single_user_profile } from '../../../../app/pages/account/api/accountsApi'
type User = {
  id: number;
  department_id: string;
  department_name: string | undefined;
  title: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  lastname: string | undefined;
  telephone: string | undefined;
  full_name:string | undefined;
  email: string | undefined;
  password: string | undefined;
  mobile: string | undefined;
  picture: string | undefined;
  role: string | undefined;
  password_confirmation: string | undefined;
  status: number | undefined;
  created_at: string;
  deleted_at: string;
  updated_at: string;
};
const HeaderUserMenu: FC = () => {
  const {currentUser} = useAuth()
  const {logout} = useAuth()
  const navigate = useNavigate()
  const authData = (() => {
    const data = localStorage.getItem('kt-auth-react-v');
    try {
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error parsing kt-auth-react-v from localStorage:', error);
      return null;
    }
  })();
  
  const access_token: string = authData?.access_token || null;
  const profileData: any = authData?.user || null;
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
       <div className='menu-item m-3'>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-50px me-2'>
          <div className='symbol symbol-100px position-relative'>
            {authData.user.profile_picture ? (
              <img
                src={authData.user.profile_picture}
                alt='Profile Pic'
                className='img-fluid rounded-circle shadow-sm'
              />
            ) : (
              <div
              className='symbol symbol-fixed position-relative'
              style={{
                width: '60px', // Set the outer container width
                height: '60px', // Set the outer container height
                borderRadius: '50%', // Make the container circular
                overflow: 'hidden', // Ensure the image doesn't exceed container bounds
              }}
            >
              <img
                src={toAbsoluteUrl('/media/avatars/blank.png')}
                alt='Metornic'
                style={{
                  width: '100%', // Image takes full width of the container
                  height: '100%', // Image takes full height of the container
                  objectFit: 'cover', // Ensure the image is cropped to fit inside
                }}
              />
            </div>            
            )}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column'>
          <div className='fw-bold fs-5 text-dark'>
            {authData.user.full_name}
            <span className='badge badge-light-success ms-2'>Online</span>
          </div>
          <span className='text-muted fs-7'>
            {authData.user.email}
          </span>
        </div>
    </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/accounts/update-profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/accounts/account-setting'} className='menu-link px-5'>
        Change Password
        </Link>
      </div>
      {/* commenting for future use */}
      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div> */}

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        {/* <a onClick={()=> logout(access_token)} className='menu-link px-5'> */}
        <a onClick={() => {
            // logout(access_token);
            navigate('/logout');
          }} 
          className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
