/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { getOrganisationMedia } from './core/_requests';
import "../../../_metronic/assets/keenicons/duotone/style.css";
const AuthLayout = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Loading start
        const media: any = await getOrganisationMedia();
        media?.data?.data?.forEach((item: { image_key: string; image_value: string }) => {
          if (item.image_key && item.image_value) {
            localStorage.setItem(item.image_key, item.image_value);
          }
        });
        document.querySelectorAll('link[rel="apple-touch-icon"], link[rel="icon"], link[rel="manifest"]').forEach(link => {
          link.remove();
        });
      } catch (error) {
        console.error('Error fetching organisation media:', error);
      } finally {
        setLoading(false); // Loading end
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center auth-spinner-height"
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
    {/* begin::Body */}
    <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
      {/* begin::Form */}
      <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px p-10'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Form */}
    </div>
    {/* end::Body */}
      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 position-relative'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
{/* begin::Content */}
        {/* Dark Overlay */}
        <div
          className='overlay-auth-layout'
        ></div>

        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100' style={{ zIndex: 2 }}>
          {/* begin::Logo */}
          {
            (!localStorage.getItem("white_logo") || localStorage.getItem("white_logo") === 'null') ? (
              <h1 className='text-white fs-2qx fw-bolder text-center'>
           Wriko Office
            </h1>
            ) : (
              <Link to='/' className='mb-5'>
                <img
                  alt='Wriko Office'
                  src={localStorage.getItem("white_logo") || undefined}
                  className='h-75px'
                />
              </Link>
            )
          }
          {/* end::Logo */}
          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
          Organize. Track. Simplify.
          </h1>
          {/* end::Title */}
          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
          Wriko Office simplifies office inventory management with real-time tracking, low-stock alerts, and detailed reports, ensuring seamless organization and efficient control of office supplies.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
