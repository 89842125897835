import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

const CENTRAL_BASE_URL = process.env.REACT_APP_CENTRAL_API_URL;

export function Logout() {
  useEffect(() => {
    const handleLogout = async () => {
      const storedToken = localStorage.getItem('kt-auth-react-v');
      let access_token = '';

      if (storedToken) {
        try {
          const parsedToken = JSON.parse(storedToken);
          access_token = parsedToken?.access_token || '';
        } catch (error) {
          console.error('Error parsing access token:', error);
        }
      }

      if (!access_token) {
        console.warn('Access token is missing or invalid.');
        window.location.href = '/auth/login';
        return;
      }

      try {
        // Call the logout API
        const response = await fetch(`${CENTRAL_BASE_URL}/api/logout`, {
          method: 'POST', // Use POST or the method required by your API
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`, // Include token if needed
          },
        });

        if (response.ok) {
          localStorage.removeItem('kt-auth-react-v');
          localStorage.removeItem('kt-auth-react-v-expiration');
          secureLocalStorage.removeItem('user_permissions');
          // secureLocalStorage.removeItem('baseURL');
          secureLocalStorage.removeItem('general_settings');
        } else {
          console.error('Failed to call logout API:', await response.json());
        }
      } catch (error) {
        console.error('An error occurred during logout:', error);
      } finally {
        // Redirect to the login page regardless of API response
        window.location.href = '/auth/login';
      }
    };

    handleLogout();
  }, []);

  return <></>;
}