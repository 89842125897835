import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from 'react-query';
import { addOrganization, country } from '../../../modules/accounts/components/settings/SettingsModel';
import { add_general_settings, add_general_settings_for_tenant, get_all_general_settings } from '../api/generalSettingsApi';
import { get_tenant_setting } from '../../users/api/usersApi';
import { validateImage } from '../../../helper-functions/ImageValidation';

function GeneralSettings() {
  const navigate = useNavigate();
  const [favIcon, setFavIcon] = useState<any>("");
  const [whiteLogo, setWhiteLogo] = useState<any>("");
  const [coloredLogo, setColoredLogo] = useState<any>("");
  // const [preloader, setPreloader] = useState<any>("");
  const [InitialFavIcon, setInitialFavIcon] = useState<any>();
  const [InitialWhiteLogo, setInitialWhiteLogo] = useState<any>("");
  const [InitialColoredLogo, setInitialColoredLogo] = useState<any>("");
  // const [InitialPreloader, setInitialPreloader] = useState<any>("");
  const { data, isLoading } = useQuery('generalSettings', get_all_general_settings, { refetchOnWindowFocus: false });
  const [initialValues, setInitialValues] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [organisationApplicationData, setOrganisationApplicationData] = useState<{
    application_id: number;
    organization_id: number;
  }>({
    application_id: 0,
    organization_id: 0,
  });
  useEffect(() => {
    if (data && data.data) {
      const keyData = data.data;
      keyData.forEach(element => {
        let rowElements = document.getElementById(element.setting_key);
        if (rowElements) {
          rowElements.classList.remove("d-none")
        }
      });
      try {
        const initialVals = data.data.reduce((acc: any, { setting_key, setting_value }: any) => {
          acc[setting_key] = setting_value;
          return acc;
        }, {});
        // Set the state values based on the API response
        setInitialFavIcon(initialVals.favicon);
        setInitialColoredLogo(initialVals.colored_logo);
        setInitialWhiteLogo(initialVals.white_logo);
        // setInitialPreloader(initialVals.preloader);

        // Assign API data to initialValues
        formik.setValues(initialVals);
      } catch (error) {
        console.error("Error processing API response:", error);
      }
    }
  }, [data]);
  useEffect(() => {
    const fetchTenantSetting = async () => {
      try {
        const result = await get_tenant_setting();
        console.log(result);
        setOrganisationApplicationData(result);
      } catch (error) {
        console.error('Error fetching tenant settings:', error);
      }
    };

    fetchTenantSetting();
  }, []);
  const organizationDetailsSchema = Yup.object().shape({
    organisation_name: Yup.string().required("Organisation Name is required"),
    // address_line_1: Yup.string().required("Address Line 1 is required"),
    // country: Yup.string().required("Country is required"),
    // city: Yup.string().required("City is required"),
    // post_code: Yup.string().required("Postcode is required"),
    // phone_1: Yup.string().required("Phone 1 is required"),
    // email: Yup.string().required("Email is required"),
    currency_name: Yup.string().required("Currency Name is required"),
    currency_symbol: Yup.string().required("Currency Symbol is required"),
  });
  const formik = useFormik<addOrganization>({
    initialValues,
    validationSchema: organizationDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      // Helper function to ensure no null values
      const sanitizeValue = (value: any) => (value == null ? '' : value);
      const formData = new FormData();
      const formDataForTenant = new FormData();
      const { organization_id, application_id } = organisationApplicationData;
      formData.append('organisation_name', sanitizeValue(values.organisation_name));
      formData.append('address_line_1', sanitizeValue(values.address_line_1));
      formData.append('address_line_2', sanitizeValue(values.address_line_2));
      formData.append('country', sanitizeValue(values.country));
      formData.append('city', sanitizeValue(values.city));
      formData.append('post_code', sanitizeValue(values.post_code));
      formData.append('phone_1', sanitizeValue(values.phone_1));
      formData.append('phone_2', sanitizeValue(values.phone_2));
      formData.append('email', sanitizeValue(values.email));
      formData.append('website', sanitizeValue(values.website));
      formData.append('currency_name', sanitizeValue(values.currency_name));
      formData.append('currency_symbol', sanitizeValue(values.currency_symbol));
      if (favIcon) {
        formData.append('favicon', favIcon);
      }
      // else if (InitialFavIcon) {
      //   formData.append('favicon', InitialFavIcon);
      // };
      if (whiteLogo) {
        formData.append('white_logo', whiteLogo);
      }
      // else if (InitialWhiteLogo) {
      //   formData.append('white_logo', InitialWhiteLogo);
      // };
      if (coloredLogo) {
        formData.append('colored_logo', coloredLogo);
      }
      // else if (InitialColoredLogo) {
      //   formData.append('colored_logo', InitialColoredLogo);
      // };
      // if (preloader) {
      //   formData.append('preloader', preloader);
      // }
      // else if (InitialPreloader) {
      //   formData.append('preloader', InitialPreloader);
      // };
      formDataForTenant.append('name', sanitizeValue(values.organisation_name));
      formDataForTenant.append('address_1', sanitizeValue(values.address_line_1));
      formDataForTenant.append('address_2', sanitizeValue(values.address_line_2));
      formDataForTenant.append('country', sanitizeValue(values.country));
      formDataForTenant.append('city', sanitizeValue(values.city));
      formDataForTenant.append('post_code', sanitizeValue(values.post_code));
      formDataForTenant.append('mobile', sanitizeValue(values.phone_1));
      formDataForTenant.append('phone', sanitizeValue(values.phone_2));
      formDataForTenant.append('email', sanitizeValue(values.email));
      formDataForTenant.append('website', sanitizeValue(values.website));
      formDataForTenant.append('currency_name', sanitizeValue(values.currency_name));
      formDataForTenant.append('currency_symbol', sanitizeValue(values.currency_symbol));
      if (favIcon) {
        formDataForTenant.append('favicon', favIcon);
      }
      // else if (InitialFavIcon) {
      //   formDataForTenant.append('favicon', InitialFavIcon);
      // };
      if (whiteLogo) {
        formDataForTenant.append('white_logo', whiteLogo);
      }
      // else if (InitialWhiteLogo) {
      //   formDataForTenant.append('white_logo', InitialWhiteLogo);
      // };
      if (coloredLogo) {
        formDataForTenant.append('color_logo', coloredLogo);
      }
      // else if (InitialColoredLogo) {
      //   formDataForTenant.append('color_logo', InitialColoredLogo);
      // };
      // if (preloader) {
      //   formDataForTenant.append('preloader', preloader);
      // }
      // else if (InitialPreloader) {
      //   formDataForTenant.append('preloader', InitialPreloader);
      // };
      try {
        const tenantResult = await add_general_settings_for_tenant(organization_id, formDataForTenant);
        const result = await add_general_settings(formData);
        // navigate('/users/users-list');
        toast.success(result.message, {
          position: 'top-right',
          autoClose: 3000,
        });
    // Perform a hard refresh after successful form submission
    window.location.reload();
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      } finally {
        setLoading(false);
      }
    },
  });
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  };
  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    logoType: string,
    MAX_FILE_SIZE: number,
    ALLOWED_TYPES: string[],
    MAX_DIMENSIONS: { [key: string]: { width: number; height: number } }
  ) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      // Call the validation function
      validateImage(
        newFile,
        logoType,
        (validFileUrl) => {
          // If valid, update the state with the image URL
          if (logoType === 'color_logo') {
            setColoredLogo(newFile);
          } else if (logoType === 'white_logo') {
            setWhiteLogo(newFile);
          } else if (logoType === 'favicon') {
            setFavIcon(newFile);
          }
        },
        MAX_FILE_SIZE, // File size limit in MB
        ALLOWED_TYPES, // Allowed file types
        MAX_DIMENSIONS // Maximum dimensions per logo type
      );
    }
  };
  function handleActivityLogs() {
    navigate('/generalSettings/general-setting/logs');
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>General Settings</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
              <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="currentColor"></path><path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="currentColor"></path></svg>
              </span>
              <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                <h5 className="mb-1">Note!</h5>
                <span>The changes in the settings will be effected on next login.</span>
              </div>
              <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                <span className="svg-icon svg-icon-2x svg-icon-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg>
                </span>
              </button>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                data-toggle='tooltip'
                data-placement='bottom'
                title='Activity Logs'
                className='btn btn-primary'
                onClick={() => {
                  handleActivityLogs()
                }}
              >
                Activity Logs
              </button>
            </div>
            {data.data[0]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[0]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Organisation Name:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Organisation Name'
                    {...formik.getFieldProps('organisation_name')}
                  />
                  {formik.touched.organisation_name && formik.errors.organisation_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.organisation_name}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {data.data[1]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[1]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Address Line 1:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address Line 1'
                    {...formik.getFieldProps('address_line_1')}
                  />
                  {/* {formik.touched.address_line_1 && formik.errors.address_line_1 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address_line_1}</div>
                    </div>
                  )} */}
                </div>
              </div>
            )}
            {data.data[2]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[2]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Address Line 2:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address Line 2'
                    {...formik.getFieldProps('address_line_2')}
                  />
                </div>
              </div>
            )}
            {data.data[3]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[3]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Country:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('country')}
                  >
                    <option value='' disabled>Select Country</option>
                    {country.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {/* {formik.touched.country && formik.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.country}</div>
                    </div>
                  )} */}
                </div>
              </div>
            )}
            {data.data[4]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[4]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>City:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='City'
                    {...formik.getFieldProps('city')}
                  />
                  {/* {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.city}</div>
                    </div>
                  )} */}
                </div>
              </div>
            )}
            {data.data[5]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[5]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Postcode:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Postcode'
                    {...formik.getFieldProps('post_code')}
                  />
                  {formik.touched.post_code && formik.errors.post_code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.post_code}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {data.data[6]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[6]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Phone 1:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone 1'
                    {...formik.getFieldProps('phone_1')}
                  />
                  {/* {formik.touched.phone_1 && formik.errors.phone_1 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone_1}</div>
                    </div>
                  )} */}
                </div>
              </div>
            )}
            {data.data[7]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[7]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Phone 2:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone 2'
                    {...formik.getFieldProps('phone_2')}
                  />
                </div>
              </div>
            )}
            {data.data[8]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[8]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Email:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                  />
                  {/* {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )} */}
                </div>
              </div>
            )}
            {data.data[9]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[9]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Website:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Website'
                    {...formik.getFieldProps('website')}
                  />
                </div>
              </div>
            )}
            {data.data[10]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[10]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Currency Name:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Currency Name'
                    {...formik.getFieldProps('currency_name')}
                  />
                  {formik.touched.currency_name && formik.errors.currency_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.currency_name}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {data.data[11]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[11]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Currency Symbol:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Currency Symbol'
                    {...formik.getFieldProps('currency_symbol')}
                  />
                  {formik.touched.currency_symbol && formik.errors.currency_symbol && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.currency_symbol}</div>
                    </div>
                  )}
                  <p className='text-danger pt-1'>The changes will effect after getting logged in again.</p>
                </div>
              </div>
            )}
            {data.data[12]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[12]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Favicon:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Favicon'
                    onChange={(e) => handleImageChange(
                      e,
                      'favicon',
                      2, // Max file size (2MB)
                      ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                      {
                        favicon: { width: 100, height: 100 }
                      } // Max dimensions
                    )}
                    accept="image/jpeg, image/jpg, image/png"
                  />
                  <div className='text-danger mt-2'>
                    Recommended size: 100 x 100 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
                  </div>
                  {InitialFavIcon && (
                    <img  className="img-fluid edit-organisation-img-icon bg-dark rounded" src={InitialFavIcon} alt='favicon' />
                  )}
                </div>
              </div>
            )}
            {data.data[13]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[13]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>White Logo:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='White Logo'
                    onChange={(e) => handleImageChange(
                      e,
                      'white_logo',
                      2, // Max file size (2MB)
                      ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                      {
                        white_logo: { width: 400, height: 400 }
                      } // Max dimensions
                    )}
                    accept="image/jpeg, image/jpg, image/png"
                  />
                  <div className='text-danger mt-2'>
                    Recommended size: 400 x 400 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
                  </div>
                  {InitialWhiteLogo && (
                    <img  className="img-fluid edit-organisation-img bg-dark rounded" src={InitialWhiteLogo} alt='white_logo' />
                  )}
                </div>
              </div>
            )}
            {data.data[14]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[14]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Coloured Logo:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Coloured Logo'
                    onChange={(e) => handleImageChange(
                      e,
                      'color_logo',
                      2, // Max file size (2MB)
                      ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                      {
                        color_logo: { width: 400, height: 400 }
                      } // Max dimensions
                    )}
                    accept="image/jpeg, image/jpg, image/png"
                  />
                  <div className='text-danger mt-2'>
                    Recommended size: 400 x 400 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
                  </div>
                  {InitialColoredLogo && (
                    <img  className="img-fluid edit-organisation-img bg-dark rounded" src={InitialColoredLogo} alt='colored_logo' />
                  )}
                </div>
              </div>
            )}
            {/* {data.data[15]?.setting_key && (
              <div className='row mb-6 d-none' id={data.data[15]?.setting_key}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Preloader:</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Preloader'
                    onChange={(e) => HandlePreloaderChange(e)}
                  />
                  <div className='text-danger mt-2'>
                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                  </div>
                  {InitialPreloader && (
                    <img className='mt-5' height={100} width={100} src={InitialPreloader} alt='preloader' />
                  )}
                </div>
              </div>
            )} */}
          </div>
          <div className='card-footer d-flex justify-content-end pt-6 pb-2 px-9'>
            <button
              data-toggle='tooltip'
              data-placement='bottom'
              title='Save Settings'
              type='submit'
              className='btn btn-primary'
              disabled={loading}
            >
              {!loading && 'Save Settings'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <br />
          </div>
          <div className='text-danger mt-2 float-end py-5 px-3'>
            Note! The changes in the settings will be effected on next login.
          </div>
        </form>
      </div>
    </div>
  )
};
export default GeneralSettings;