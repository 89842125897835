import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from 'react-query';
import { addCategories, initialValues, } from '../../../modules/accounts/components/settings/SettingsModel';
import { useNavigate } from 'react-router-dom'
import { add_category } from '../api/categoriesApi';
import { toast } from 'react-toastify';
const profileDetailsSchema = Yup.object().shape({
    categoryName: Yup.string().required("Category Title is Required"),
})
function AddCategory() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any | null>(null);
    const navigate = useNavigate()
    const mutation = useMutation(add_category, {
        onSuccess: (data) => {
            toast.success(data.message, {
                position: "top-right",
                autoClose: 3000,
            });
            navigate('/categories/categories-list')
        },
        onError: (error: any) => {
            setError(error.response.data.message)
            setLoading(false)
        }

    });
    const formik = useFormik<addCategories>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            setLoading(true)
            setError(null);
            mutation.mutate({
                "title": values.categoryName,
                "status": values.status,
            });
        },
    }
    )
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Add Category</h3>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Category Title</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder="Category Title"
                                    {...formik.getFieldProps('categoryName')}
                                />
                                {formik.touched.categoryName && formik.errors.categoryName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.categoryName}</div>
                                    </div>
                                )}
                                {error && <div className='text-danger'>{error}</div>}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Status</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('status')}
                                >
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading} data-toggle="tooltip" data-placement="bottom" title="Add new Category">
                            {!loading && 'Add Category'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddCategory
