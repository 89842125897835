import { useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { get_single_purchase_order } from '../api/purchasesApi';
import { Form } from 'react-bootstrap';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import { formatDateWithTime, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

interface SinglePurchaseOrder {
    id: number;
    PO_date: string;
    vendor_id: number | undefined;
    vendor_name: string;
    grand_total: number | undefined;
    status: string | undefined;
    updated_by: string;
    updated_at: string;
    purchase_order_products: {
        id: number;
        product_id: number;
        product_name: string | undefined;
        quantity: number;
        rate: number;
        price: number;
        discount: number;
        final_price: number;
        updated_by: string;
        created_at: string;
        updated_at: string;
    }[];
}
function ViewPurchase() {
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [singlePurchaseOrder, setSinglePurchaseOrder] = useState<SinglePurchaseOrder | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const id = (location.state as { id: number }).id;
    const { data } = useQuery(['ViewPurchaseOrder', id], () =>
        setSelectedId(id), {refetchOnWindowFocus: false}
    );

    const { data: singlePurchaseOrderData, isLoading: isSinglePurchaseOrderLoading } = useQuery(
        ['SinglePurchaseOrder', selectedId],
        () => get_single_purchase_order(selectedId!),
        {
            refetchOnWindowFocus: false,
            enabled: !!selectedId,
        }
    );
    useEffect(() => {
        if (singlePurchaseOrderData) {
            setSinglePurchaseOrder(singlePurchaseOrderData.data as SinglePurchaseOrder);
        }
    }, [singlePurchaseOrderData]);
    return (
        <div className='card card-custom shadow'>
            <div
                className='card-header border-0'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Purchase Order Details </h3>
                </div>
            </div>
            {isSinglePurchaseOrderLoading ? (
                <tr>
                    <td colSpan={6}>
                        <div className='text-center'>
                            <div className='spinner-border' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </div>
                        </div>
                    </td>
                </tr>
            ) : singlePurchaseOrder ? (
                <>
                    <div className='card-header border-0 pt-5'>
                        <div className="card-body">

                            <Form>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                        <span className='required'>PO Date</span>
                                    </label>
                                    <div className='col-lg-4 fv-row'>
                                    <label className='form-control form-control-lg form-control-solid'>
                                            {formatDateWithoutTime(singlePurchaseOrder.PO_date)}
                                        </label>

                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                        <span className='required'>Vendor</span>
                                    </label>
                                    <div className='col-lg-4 fv-row'>
                                        <label className='form-control form-control-lg form-control-solid'>
                                            {singlePurchaseOrder.vendor_name}
                                        </label>
                                    </div>

                                </div>
                            </Form>
                        </div>

                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <Form>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-100px required'>Product</th>
                                            <th className='min-w-100px required'>Quantity</th>
                                            <th className='min-w-100px required'>Rate ({getSettingValue('currency_symbol')})</th>
                                            <th className='min-w-100px required'>Price ({getSettingValue('currency_symbol')})</th>
                                            <th className='min-w-100px'>Discount ({getSettingValue('currency_symbol')})</th>
                                            <th className='min-w-150px required'>Final Price ({getSettingValue('currency_symbol')})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {singlePurchaseOrder &&
                                            singlePurchaseOrder.purchase_order_products.map((purchase, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type="text"
                                                                readOnly  // Make the input read-only to display the calculated value
                                                                value={purchase.product_name}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type="number"
                                                                value={purchase.quantity}
                                                                readOnly 
                                                            />
                                                        </td>

                                                        <td>
                                                            <input
                                                                className='card'
                                                                type="number"
                                                                value={purchase.rate}
                                                                readOnly 
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type="number"
                                                                readOnly  // Make the input read-only to display the calculated value
                                                                value={purchase.price}
                                                            />
                                                        </td>

                                                        <td>
                                                            <input
                                                                className='card'
                                                                type="text"
                                                                value={purchase.discount}
                                                                readOnly 
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type="number"
                                                                readOnly  // Make the input read-only to display the calculated value
                                                                value={purchase.final_price}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                    <tfoot>
                                        <tr className='fw-bold text-muted'>
                                            <td colSpan={5}></td>
                                            <td colSpan={2}>Grand Total ({getSettingValue('currency_symbol')}):
                                                <input
                                                    className='card'
                                                    type="number"
                                                    readOnly
                                                    value={singlePurchaseOrder.grand_total}
                                                />
                                            </td>
                                            <td className='min-w-140px'></td>
                                            <td colSpan={2}></td>
                                            <td colSpan={1}></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Form>
                        </div>

                    </div>
                </>
            ): "null"}
        </div>
    )
}

export default ViewPurchase
