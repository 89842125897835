import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get_all_requisition_type } from '../../requisitionsType/api/requisitionsTypeApi';
import { get_all_active_products } from '../../../products/api/productsApi';
import { addRequisition, addRequisitionsInitValues as initialValues } from '../../../../modules/accounts/components/settings/SettingsModel';
import { Button, Form } from 'react-bootstrap';
import { add_requisition, all_requsition_assignees } from '../api/requisitionsApi';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { mediaPath } from '../../../../helper-functions/ImageStoragePath';
const imagePng = `${mediaPath()}/png.png`;
const imagePdf = `${mediaPath()}/pdf.png`;
const imageJpg = `${mediaPath()}/jpg-file.png`;
const requisitionSchema = Yup.object().shape({
  requisitionTitle: Yup.string().required('Requisition Title is required'),
  requisitionType: Yup.string().required('Requisition Type is required'),
  products: Yup.array().of(
    Yup.object().shape({
      product: Yup.string().required('Product Name is required'),
      quantity: Yup.number().required('Quantity is required'),
    })
  ),
  requisitionAssignees: Yup.array().required('At least one assignee is required'),
});

interface RequisitionType {
  id: number;
  title: string;
};
interface Product {
  id: number;
  name: string;
};
interface User {
  id: number;
  full_name: string;
};
function AddRequisition() {
  const [requisitionTypes, setRequisitionTypes] = useState<RequisitionType[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [showForm, setShowForm] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [assigneeValidationError, setAssigneeValidationError] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [fileValue, setFileValue] = useState<File | null>(null);
  const [fileValidationError, setFileValidationError] = useState('');
  const [dateState, setDateState] = useState<any>({
    date1: new Date(),
    date2: null
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formRef = useRef<HTMLFormElement>(null);
  const { data: requisitiontypeData } = useQuery('requisitionType', get_all_requisition_type, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (requisitiontypeData) {
      setRequisitionTypes(requisitiontypeData.data as RequisitionType[]);
    };
  }, [requisitiontypeData]);

  const { data: productsData } = useQuery('products', get_all_active_products, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (productsData) {
      setProducts(productsData.data as Product[]);
    };
  }, [productsData]);
  const { data: userData } = useQuery('users', all_requsition_assignees, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (userData) {
      setUsers(userData.data as User[]);
    };
  }, [userData]);
  //mutation to add requisition
  const mutation = useMutation(add_requisition, {
    onSettled: (data) => {
      toast.success(data.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/requisitions/requisitions-list');
      queryClient.invalidateQueries('requisition');
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
    },
  });
  const formik = useFormik<addRequisition>({
    initialValues,
    validationSchema: requisitionSchema,
    onSubmit: (values, action) => {
      mutation.mutate({
        title: values.requisitionTitle,
        description: values.requisitionDescription,
        due_date: values.dueDate ? new Date(values.dueDate).toISOString().split('T')[0] : null,
        requisition_type_id: values.requisitionType,
        products: values.products.map((product) => ({
          product_id: product.product,
          quantity: product.quantity,
        })),
        requisition_documents: values.requisition_documents.map((data, index) => ({
          document_title: data.document_title,
          document_description: data.document_description,
          document_path: data.document_path,
          document_counter: values.requisition_documents.length,
        })),
        assignees: values.requisitionAssignees.map((user) => {
          return {
            user_id: user.value,
          };
        }),
      });
    },
  });
  //functions to handle requisition products
  const handleAddProduct = () => {
    formik.setFieldValue('products', [
      { product: null, quantity: 0 },
      ...formik.values.products,
    ]);
  };
  const handleRemoveProduct = (index: number) => {
    const updatedProducts = [...formik.values.products];
    updatedProducts.splice(index, 1);
    formik.setFieldValue('products', updatedProducts);
  };

  //functions to handle requisition documents
  const HandleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFileValue(e.target.files[0]);
    }
  };
  const handleFileValidation = () => {
    if (!fileValue) {
      setFileValidationError('File is required.');
      return;
    } else {
      setFileValidationError('');
    }
  };
  const handleAddDocument = () => {
    formik.setFieldValue('requisition_documents', [
      ...formik.values.requisition_documents,
      {
        document_title: titleValue,
        document_description: descriptionValue,
        document_path: fileValue,
      },
    ]);
    if (formRef.current) {
      formRef.current.reset();
    }
    setTitleValue('');
    setDescriptionValue('');
    setFileValue(null);
    setFileValidationError('');
  };
  const handleRemoveDocument = (index: number) => {
    const updatedDocuments = [...formik.values.requisition_documents];
    updatedDocuments.splice(index, 1);
    formik.setFieldValue('requisition_documents', updatedDocuments);
  };
  //requisition assignee
  const handleAssigneeChange = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setAssigneeValidationError('Assignee is required.');
    } else {
      setAssigneeValidationError('');
    }
  };
  // File Extension 
  const determineFileImage = (fileName: string | undefined) => {
    const fileExtension = fileName?.split('.')?.pop()?.toLowerCase();
    switch (fileExtension) {
      case 'png':
        return imagePng;
      case 'pdf':
        return imagePdf;
      case 'jpg':
      case 'jpeg':
        return imageJpg;
      default:
        return ''; // Return a default image or handle other file types as needed
    }
  }
  const handleRemoveExistingDocument = () => {
    // Handle the removal of the document and hide the form
    setShowForm(false);
  }
  const handleAddDocumentAgain = () => {
    setShowForm(true);
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Requisition</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Requisition Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Requisition Title'
                  {...formik.getFieldProps('requisitionTitle')}
                />
                {formik.touched.requisitionTitle && formik.errors.requisitionTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.requisitionTitle}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Short Description'
                  {...formik.getFieldProps('requisitionDescription')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Due Date</label>
              <div className='col-lg-8 fv-row'>
                <Flatpickr
                  value={dateState.date2}
                  onChange={([date2]) => {
                    setDateState({ date2 });
                    formik.setFieldValue('dueDate', date2 ? date2.toISOString() : null);
                    // Check if date2 is null and set an error message
                    if (!date2) {
                      formik.setFieldError('dueDate', 'Due Date is required');
                    } else {
                      formik.setFieldError('dueDate', ''); // Clear the error message if date2 is selected
                    }
                  }}
                  className='form-control form-control-solid'
                  placeholder='Due Date'
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
                {formik.touched.dueDate && formik.errors.dueDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.dueDate}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              {/* Requisition Assignee */}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Requisition Assignee</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('requisitionAssignees')}
                  isMulti
                  placeholder="Select "
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.full_name,
                  }))}
                  className="basic-multi-select form-select-lg p-2 bg-light text-black"
                  classNamePrefix="select border-light fw-bold"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue('requisitionAssignees', selectedOptions);
                  }}
                />
                {assigneeValidationError && (
                  <div className='text-danger'>{assigneeValidationError}</div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              {/* Requisition Type */}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Requisition Type</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('requisitionType')}
                >
                  <option value=''>Select a Type...</option>
                  {Array.isArray(requisitionTypes) && requisitionTypes.length > 0 ? (
                    requisitionTypes.map((reqType) => (
                      <option key={reqType.id} value={reqType.id}>
                        {reqType.title}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No requisition type available</option>
                  )}
                </select>
                {formik.touched.requisitionType && formik.errors.requisitionType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.requisitionType}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row m-3'>
              <div className='col-lg-4'></div>
              <div className='col-lg-8 card p-5 border-warning '>
                <div className='mt-1 card-header'>
                  <div className='card-title m-0 pb-5'>
                    <Button data-toggle="tooltip" data-placement="bottom" title="Add Product" variant='primary' onClick={handleAddProduct}>
                      Add Product
                    </Button>
                  </div>
                </div>
                {formik.values.products.map((product, index) => (
                  <div key={index} className='p-2 mt-2 card-body border'>
                    {/* Remove Product Button */}
                    <div className='justify-content-end d-flex'>
                      <button
                        data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                        type='button'
                        className='btn btn-custom-style-cross btn-danger'
                        onClick={() => handleRemoveProduct(index)}
                      >
                        X
                      </button>
                    </div>
                    {/* Product Name */}
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product Name</label>
                      <div className='col-lg-8 fv-row'>
                        <select
                         className='form-select form-select-solid form-select-lg fw-bold'
                          value={formik.values.products[index]?.product} // Assuming this is the selected value
                          onChange={(event) => {
                            const selectedProductId = parseInt(event.target.value);
                            formik.setFieldValue(`products[${index}].product`, selectedProductId);
                          }}
                        >
                          <option value=''>Select a Product...</option>
                          {products
                            .filter(product =>
                              !formik.values.products.some((formProduct, i) =>
                                i !== index && formProduct.product === product.id
                              )
                            )
                            .map((product) => (
                              <option
                                key={product.id}
                                value={product.id}
                              >
                                {product.name}
                              </option>
                            ))
                          }
                        </select>

                        {formik.touched.products &&
                          formik.errors.products &&
                          formik.errors.products[index] &&
                          (formik.errors.products[index] as FormikErrors<{
                            product: string;
                            quantity: number;
                          }>).product && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {(formik.errors.products[index] as FormikErrors<{
                                  product: string;
                                  quantity: number;
                                }>).product}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    {/* Quantity */}
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Quantity'
                          {...formik.getFieldProps(`products[${index}].quantity`)}
                        />
                        {formik.touched.products &&
                          formik.errors.products &&
                          formik.errors.products[index] &&
                          (formik.errors.products[index] as FormikErrors<{
                            product: string;
                            quantity: number;
                          }>).quantity && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {(formik.errors.products[index] as FormikErrors<{
                                  product: string;
                                  quantity: number;
                                }>).quantity}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* showing documnts in requisition  */}
            <div className='row m-1'>
              <div className='col-lg-4'></div>
              <div className='col-lg-8  mb-2 mt-1'>
                {formik.values.requisition_documents.map((document: any, index) => (
                  <div className='shadow border-danger mt-4' key={index}>
                    <div className='card-header'>
                      <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Document</h3>
                      </div>
                    </div>
                    <Form className='card-body row'>
                      <div className=' col-lg-8 mt-5'>
                        <div className='form-group'>
                          <div className='row mb-6'>
                            <div className='col-lg-10 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Title'
                                {...formik.getFieldProps(`requisition_documents[${index}].document_title`)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='row mb-6'>
                            <div className='col-lg-10 fv-row mt-3'>
                              <input
                                type='textarea'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Description'
                                {...formik.getFieldProps(`requisition_documents[${index}].document_description`)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 mx-6 mt-4'>
                        <div className="symbol symbol-125px me-10 mb-10 ">
                          <div className='symbol-label fs-6 fw-bold text-success'>
                            <img className='document-image' src={determineFileImage(document?.document_path?.name)} alt="Document" width="100px" />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className='card-footer border '>
                      <button
                        data-toggle="tooltip" data-placement="bottom" title="Remove this document"
                        type='button'
                        className='btn btn-danger '
                        onClick={() => handleRemoveDocument(index)}
                      >
                        Remove Document
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* form to add requisition documents  */}
            <div className='row m-3' id='Add-New-Document'>
              <div className='col-lg-4'></div>
              <div className='col-lg-8 card mb-5 mt-1 border-primary'>
                <div className='mt-5 card-header'>
                  <div className='card-title m-0 pb-5'>
                    <Button data-toggle="tooltip" disabled={showForm} data-placement="bottom" title="Add Product" variant='primary' onClick={handleAddDocumentAgain}>
                      Add Document
                    </Button>
                  </div>
                </div>
                <div>
                  {showForm && (
                    <div>
                      <Form className='card-body' ref={formRef}>
                        <div >
                          <div className='form-group'>
                            <div className='row mb-6'>
                              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Title</span>
                              </label>
                              <div className='col-lg-8 fv-row'>
                                <input
                                  type='text'
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Title'
                                  value={titleValue}
                                  onChange={(e) => {
                                    setTitleValue(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='row mb-6'>
                              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Description
                              </label>
                              <div className='col-lg-8 fv-row'>
                                <textarea
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Description'
                                  value={descriptionValue}
                                  onChange={(e) => {
                                    setDescriptionValue(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='row'>
                              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                File
                              </label>
                              <div className='col-lg-8 fv-row'>
                                <input
                                  type='file'
                                  className='form-control form-control-lg form-control-solid'
                                  onChange={(e) => {
                                    HandleImageChange(e)
                                    handleFileValidation()
                                  }}
                                  name='picture'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className='card-footer d-flex justify-content-between py-6 px-9'>
                        <button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Remove this field"
                          type='button'
                          className='btn btn-danger '
                          onClick={handleRemoveExistingDocument}
                        >
                          Remove Document
                        </button>
                        {!fileValidationError ? (
                          <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary disabled'>
                            Add Document
                          </button>
                        ) : (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Add Document"
                            type='button'
                            className='btn btn-primary'
                            onClick={handleAddDocument}
                            disabled={loading}
                          >
                            {!loading ? 'Add Document' : 'Please wait...'}
                            {loading && (
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Submit Button */}
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button data-toggle="tooltip" data-placement="bottom" title="Add New Requisition" type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? 'Add Requisition' : 'Please wait...'}
              {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddRequisition;