import { toast } from 'react-toastify';
export const validateImage = (
    file: File,
    logoType:string,
    onValid: (fileUrl: string) => void,
    MAX_FILE_SIZE: number,
    ALLOWED_TYPES: string[],
    MAX_DIMENSIONS: { [key: string]: { width: number; height: number } }
  ) => {
    // Check if file is selected
    if (!file) {
      toast.warning('No file selected. Please choose an image file.', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { backgroundColor: '#FFD966', color: '#000' },
      });
      return;
    }
    // Check file size
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
    if (fileSizeMB > MAX_FILE_SIZE) {
      toast.warning(`File is too large. Please upload an image smaller than ${MAX_FILE_SIZE}MB.`, {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { backgroundColor: '#FFD966', color: '#000' },
      });
      return;
    }
    // Check file type
    if (!ALLOWED_TYPES.includes(file.type)) {
      toast.warning('Unsupported file type. Please upload a JPG, JPEG, or PNG image.', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { backgroundColor: '#FFD966', color: '#000' },
      });
      return;
    }
    // Validate image dimensions
    const img = new Image();
    img.onload = () => {
      // Check the dimensions based on the logoType, with a fallback for an empty string
      const dimensions = logoType && MAX_DIMENSIONS[logoType]
        ? MAX_DIMENSIONS[logoType]
        : { width: 200, height: 200 }; // Fallback dimensions for empty string logoType
      if (img.width > dimensions.width || img.height > dimensions.height) {
        toast.warning(`Image dimensions should not exceed ${dimensions.width}x${dimensions.height} pixels.`, {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: { backgroundColor: '#FFD966', color: '#000' },
        });
        return;
      }
      // Call the onValid callback with the image URL
      onValid(URL.createObjectURL(file));
    };
    img.onerror = () => {
      toast.error('Invalid image file. Please try again with a valid image.', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { backgroundColor: '#F8D7DA', color: '#000' },
      });
    };
    img.src = URL.createObjectURL(file);
  };