import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get_all_active_products } from '../../../products/api/productsApi';
import { addQuotation } from '../../../../modules/accounts/components/settings/SettingsModel';
import { addQuotationsInitValues as initialValues } from '../../../../modules/accounts/components/settings/SettingsModel';
import { get_all_active_vendors } from '../../../vendors/api/vendorsApi';
import { all_accepted_requisitions, all_accepted_requisitions_products, all_requsition_assignees } from '../../../requisitions/requisitions/api/requisitionsApi';
import { add_quotation } from '../api/quotationsApi';
import Select from 'react-select';
import { KTIcon } from '../../../../../_metronic/helpers';
import { Button, Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { mediaPath } from '../../../../helper-functions/ImageStoragePath';
import { getSettingValue } from '../../../../helper-functions/general_settings_helper';
import "../../../../../_metronic/assets/keenicons/duotone/style.css"
const imagePng = `${mediaPath()}/png.png`;
const imagePdf = `${mediaPath()}/pdf.png`;
const imageJpg = `${mediaPath()}/jpg-file.png`;

const quotationSchema = Yup.object().shape({
  title: Yup.string().required('Quotation Title is required'),
  requisition: Yup.string().required('Requisition Title is required'),
  due_date: Yup.string().required('Due Date is required'),
  quotationDetailPrices: Yup.array().of(
    Yup.object().shape({
      supplier: Yup.string().required('Vendor is required'),
      price: Yup.number().required('Price is required'),
      // approved: Yup.number().required('Approval status is required'),
    })
  ),
});
interface Requisition {
  id: number;
  title: string;
  requisition: {
    id: number;
    title: string;
    description: string;
    status: string;
    due_date: string;
    requisition_type_id: number;
    requisition_type_title: string;
    completed: number;
    stock_delivered: number;
  };
  requisition_products: {
    id: number;
    quantity: number;
    updated_by: string;
    updated_at: string;
    product_id: number;
  }[];
};

interface RequisitionProduct {
  id: number;
  quantity: number;
  updated_by: string;
  updated_at: string;
  product_id: number;
  product_name: string;
  quotationDetailPrices?: VendorPrice[]; // Add this line if vendors are included
}

interface VendorPrice {
  id: number;
  supplier: string;
  price: number;
  approved: boolean;
}

interface Product {
  id: number;
  name: string;
}

interface Vendor {
  id: number;
  supplier_name: string;
}

interface User {
  id: number;
  full_name: string;
}
function AddQuotation() {
  const [requisitionArray, setRequisitionArray] = useState<Requisition[]>([]);
  const [requisitionProducts, setRequisitionProducts] = useState<RequisitionProduct[]>([]);
  const [vendorArray, setVendorArray] = useState<Vendor[]>([]);
  const [vendorValue, setVendorValue] = useState<Vendor[]>([]);
  const [documentvendorArray, setDocumentVendorArray] = useState<Vendor[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [assigneeValidationError, setAssigneeValidationError] = useState('');
  const [previousProductIndex, setPreviousProductIndex] = useState(null);
  const [showNewFields, setShowNewFields] = useState(true);
  const [documentVendor, setDocumentVendor] = useState('');
  const [documentVendorValidation, setDocumentVendorValidation] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [fileValidationError, setFileValidationError] = useState('');
  const [productValidationError, setProductValidationError] = useState('');
  const [vendorValidationError, setVendorValidationError] = useState('');
  const [activeProductIndices, setActiveProductIndices] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [dateState, setDateState] = useState<any>({
    date1: new Date(),
    date2: null
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // Getting API data and storing in states
  const { data: requisitionAllData } = useQuery('requisitionData', all_accepted_requisitions, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (requisitionAllData) {
      setRequisitionArray(requisitionAllData.data as Requisition[]);
    }
  }, [requisitionAllData]);
  const { data: productsData } = useQuery('products', get_all_active_products, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (productsData) {
      setProducts(productsData.data as Product[]);
    }
  }, [productsData]);
  const { data: vendorsData, refetch } = useQuery('vendors', get_all_active_vendors, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (vendorsData) {
      setVendorArray(vendorsData.data as Vendor[]);
      setDocumentVendorArray(vendorsData.data as Vendor[]);
    }
  }, [vendorsData]);
  const { data: userData } = useQuery('assignees', all_requsition_assignees, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (userData) {
      setUsers(userData.data as User[]);
    }
  }, [userData]);
  // mutation to add quotation
  const mutation = useMutation(add_quotation, {
    onSuccess: (data) => {
      toast.success(data.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/quotations/quotations-list');
      setLoading(false);
      queryClient.invalidateQueries('quotation');
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    },
  });
  // Function to check if a vendor is missing for any product
  const checkVendors = (quotations: any[]) => {
    let isVendorMissing = false;
    quotations.forEach((quotation, index) => {
      if (!quotation.quotationDetailPrices || quotation.quotationDetailPrices.length === 0) {
        // No quotation detail prices available for the product
        isVendorMissing = true;
        toast.error(`Please add a vendor for product ${index + 1}.`, {
          position: 'top-right',
          autoClose: 3000,
        });
      } else {
        // Check if any vendor is missing for the product
        const isAnyVendorMissing = quotation.quotationDetailPrices.some(detail => !detail.supplier);
        if (isAnyVendorMissing) {
          isVendorMissing = true;
          toast.error(`Vendor is required for product ${index + 1}.`, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      }
    });
    return isVendorMissing;
  };
  const formik = useFormik<addQuotation>({
    initialValues,
    validationSchema: quotationSchema,
    onSubmit: async (values) => {
      if (!values.quotations || values.quotations.length === 0) {
        toast.error('Please add at least one quotation detail', {
          position: 'top-right',
          autoClose: 3000,
        });
        return; // Don't proceed with the API call
      }
      // Check if any vendor is missing for products
      const isVendorMissing = checkVendors(values.quotations);
      if (isVendorMissing) {
        // Don't proceed with the API call if any vendor is missing
        return;
      }
      // Check if the first product has a vendor added
      const firstProduct = values.quotations[0];
      if (!firstProduct.quotationDetailPrices || firstProduct.quotationDetailPrices.length === 0 || !firstProduct.quotationDetailPrices[0].supplier) {
        toast.error('Please add a Vendor Against the Product before Adding the Quotation', {
          position: 'top-right',
          autoClose: 3000,
        });
        return; // Don't proceed with the API call
      }
      // Check for other missing details
      const missingDetails = values.quotations?.some((quotation, index) =>
        (quotation.quotationDetailPrices || []).some((detail) => {
          if (!detail.supplier) {
            toast.error(`Vendor is required for Quotation Item ${index + 1}`, {
              position: 'top-right',
              autoClose: 3000,
            });
            return true;
          }
          if (!detail.price) {
            toast.error(`Price is required for Quotation Item ${index + 1}`, {
              position: 'top-right',
              autoClose: 3000,
            });
            return true;
          }
          if (detail.approved === undefined) {
            toast.error(`Approval status is required for Quotation Item ${index + 1}`, {
              position: 'top-right',
              autoClose: 3000,
            });
            return true;
          }
          return false;
        })
      );
      if (missingDetails) {
        // Don't proceed with the API call if any missing details
        return;
      }
      // Check if any vendor is not approved against its products
      const vendorNotApproved = values.quotations.some((quotation) =>
        (quotation.quotationDetailPrices || []).some((detail) => detail.approved !== 1)
      );
      setLoading(true);
      try {
        await mutation.mutateAsync({
          title: values.title,
          description: values.description,
          due_date: values.due_date ? new Date(values.due_date).toISOString().split('T')[0] : null,
          requisition_id: values.requisition,
          quotation_products: values.quotations?.map((quotation) => ({
            id: quotation.id,
            quantity: quotation.quantity,
            product_id: quotation.product,
            quotation_detail_prices: quotation.quotationDetailPrices?.map((detail) => ({
              supplier_id: detail.supplier,
              price: detail.price,
              approved: detail.approved,
            })),
          })),
          quotation_assignees: values.quotationAssignees?.map((user) => ({
            user_id: user.value,
          })),
          quotation_documents: values.quotation_documents.map((data) => ({
            supplier_id: data.supplier_id,
            document_title: data.document_title,
            document_description: data.document_description,
            document_path: data.document_path,
            document_counter: values.quotation_documents.length,
          })),
        });
      } catch (error: any) {
        toast.error(error.response?.data?.message || 'An error occurred', {
          position: 'top-right',
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    },
  });
  // functions to handle quotation products
  const handleRequisitionChange = async (selectedRequisitionId) => {
    try {
      const requisitionProductsData = await all_accepted_requisitions_products(selectedRequisitionId);
      if (Array.isArray(requisitionProductsData.data) && requisitionProductsData.data.length > 0) {
        const filteredRequisitionProducts = requisitionProductsData.data.filter(
          (product) => product.requisition_id === selectedRequisitionId
        );
        setRequisitionProducts(filteredRequisitionProducts);
        // Directly set the quotations array based on requisition products
        formik.setFieldValue('quotations', filteredRequisitionProducts.map((product) => ({
          id: product.id,
          product: product.product_id,
          quantity: parseInt(product.quantity, 10),
        })));

        return filteredRequisitionProducts;
      } else {
        toast.error("No products found against this requisition", {
          position: 'top-right',
          autoClose: 3000,
        });
        setRequisitionProducts([]);
        // Set quotations as an empty array when no products are found
        formik.setFieldValue('quotations', []);
        return [];
      }
    } catch (error: any) {
      return [];
    };
  };
  const handleAddProduct = () => {
    const newProduct = {
      id: 0,
      product: '',
      quantity: 0,
      quotationDetailPrices: [],
    };
    formik.setFieldValue('quotations', [newProduct, ...formik.values.quotations]);
    // Log for testing
    console.log(...formik.values.quotations);

  };
  const handleRemoveProduct = (productIndex: number) => {
    const updatedQuotations = formik.values.quotations.filter(
      (_, index) => index !== productIndex
    );
    formik.setFieldValue('quotations', updatedQuotations);
  };
  const handleAddVendor = (productIndex: number) => {
    // Log for testing
    console.log("Adding Vendor...");

    // Clone the quotations array from Formik values
    const updatedQuotations = [...formik.values.quotations];

    // Ensure quotationDetailPrices is initialized as an array
    if (!updatedQuotations[productIndex].quotationDetailPrices) {
      updatedQuotations[productIndex].quotationDetailPrices = [];
    }

    // Add a new vendor to the quotationDetailPrices array
    const newVendor = {
      supplier: '',
      price: 0,
      approved: 0, // or false based on your design
    };

    // Use unshift to add the new vendor at the start of the array
    updatedQuotations[productIndex].quotationDetailPrices.unshift(newVendor);

    // Update the Formik values to reflect the changes
    formik.setFieldValue('quotations', updatedQuotations);
  };

  const handleRemoveVendor = (productIndex: number, vendorIndex: number) => {
    const updatedQuotations = [...formik.values.quotations];
    updatedQuotations[productIndex].quotationDetailPrices.splice(vendorIndex, 1);
    formik.setFieldValue('quotations', updatedQuotations);
  };
  const handleVendorChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    productIndex: number,
    vendorIndex: number
  ) => {
    const updatedQuotations = [...formik.values.quotations];
    updatedQuotations[productIndex].quotationDetailPrices[vendorIndex].supplier = e.target.value;
    formik.setFieldValue('quotations', updatedQuotations);
  };
  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    productIndex: number,
    vendorIndex: number
  ) => {
    const updatedQuotations = [...formik.values.quotations];
    updatedQuotations[productIndex].quotationDetailPrices[vendorIndex].price = parseFloat(e.target.value);
    formik.setFieldValue('quotations', updatedQuotations);
  };
  const handleApproveVendor = (productIndex: number, vendorIndex: number) => {
    const updatedQuotations = [...formik.values.quotations];
    updatedQuotations[productIndex].quotationDetailPrices[vendorIndex].approved =
      updatedQuotations[productIndex].quotationDetailPrices[vendorIndex].approved === 1 ? 0 : 1;
    formik.setFieldValue('quotations', updatedQuotations);
  };
  const handleProductChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    productIndex: number
  ) => {
    const updatedQuotations = [...formik.values.quotations];
    updatedQuotations[productIndex].product = e.target.value;
    formik.setFieldValue('quotations', updatedQuotations);
  };
  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    productIndex: number
  ) => {
    const updatedQuotations = [...formik.values.quotations];
    updatedQuotations[productIndex].quantity = parseInt(e.target.value);
    formik.setFieldValue('quotations', updatedQuotations);
  };

  //functions to handle quotation documents
  const HandleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFileValue(e.target.files[0]);
    };
  };
  const handleFileValidation = () => {
    if (!fileValue) {
      setFileValidationError('File is required.');
      return;
    } else {
      setFileValidationError('');
    }
  };
  const handleAddDocument = () => {
    formik.setFieldValue('quotation_documents', [
      ...formik.values.quotation_documents,
      {
        supplier_id: documentVendor,
        document_title: titleValue,
        document_description: descriptionValue,
        document_path: fileValue,
      },
    ]);
    if (formRef.current) {
      formRef.current.reset();
    }
    setDocumentVendor('')
    setTitleValue('');
    setDescriptionValue('');
    setFileValue(undefined);
    setFileValidationError('');
  };
  const handleRemoveDocument = (index: number) => {
    const updatedDocuments = [...formik.values.quotation_documents];
    updatedDocuments.splice(index, 1);
    formik.setFieldValue('quotation_documents', updatedDocuments);
  };
  // File Icon
  const renderFileIcon = (filePath: File | undefined) => {
    if (!filePath) {
      return null; // Handle the case where the file path is not available
    }
    const fileName = filePath.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return (
          <img
            src={imagePdf}
            style={{ width: '60px', height: "auto" }}
            alt='document-image'
            className='document-image'
          />
        );
      case 'png':
        return (
          <img
            src={imagePng}
            style={{ width: '60px', height: "auto" }}
            alt='document-image'
          />
        );
      case 'jpeg':
      case 'jpg':
        return (
          <img
            src={imageJpg}
            style={{ width: '60px', height: "auto" }}
            alt='document-image'
            className='document-image'
          />
        );
      default:
        return (
          <i className='bi bi-file fs-1'></i>
        ); // Default icon for unknown file types
    }
  };
  //quotation asignees
  const handleAssigneeChange = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setAssigneeValidationError('Assignee is required.');
    } else {
      setAssigneeValidationError('');
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Quotation</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Quotation Title'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Short Description'
                  {...formik.getFieldProps('description')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Valid Until</label>
              <div className='col-lg-8 fv-row'>
                <Flatpickr
                  value={dateState.date2}
                  onChange={([date2]) => {
                    setDateState({ date2 });
                    formik.setFieldValue('due_date', date2 ? date2.toISOString() : null);
                    if (!date2) {
                      formik.setFieldError('due_date', 'Due Date is required');
                    } else {
                      formik.setFieldError('due_date', '');
                    }
                  }}
                  className='form-control form-control-solid'
                  placeholder='Valid Until'
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
                {formik.touched.due_date && formik.errors.due_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.due_date}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              {/* Quotation Assignee */}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Quotation Assignee</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('quotationAssignees')}
                  isMulti
                  placeholder="Select "
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.full_name,
                  }))}
                  className="basic-multi-select form-select-lg p-2 bg-light text-black"
                  classNamePrefix="select border-light fw-bold"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue('quotationAssignees', selectedOptions);
                    handleAssigneeChange(selectedOptions)
                  }}
                />
                {assigneeValidationError && (
                  <div className='text-danger'>{assigneeValidationError}</div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              {/* Requisition*/}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Requisition</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('requisition')}
                  value={formik.values.requisition}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleRequisitionChange(Number(e.target.value));
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Select a Requisition...</option>
                  {requisitionArray.map((req) => (
                    <option key={req.requisition.id} value={req.requisition.id}>
                      {req.requisition.title}
                    </option>
                  ))}
                </select>
                {formik.touched.requisition && formik.errors.requisition && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.requisition}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row '>
              <div className='col-lg-4'></div>
              <div className='col-lg-8'>
                <div className='mt-3'>
                  <Button variant='primary' onClick={handleAddProduct}>
                    Add Product
                  </Button>
                </div>
                {showNewFields && (
                  <div>
                    {formik.values.quotations.map((product, productIndex) => (
                      <div key={productIndex} className='border border-secondary rounded p-3  mt-3'>
                        <div className='justify-content-end d-flex'>
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                            type='button'
                            className='btn btn-custom-style-cross btn-danger'
                            onClick={() => handleRemoveProduct(productIndex)}
                          >
                            X
                          </button>
                        </div>
                        {/* Product Name */}
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                            Product Name
                          </label>{' '}
                          <div className='col-lg-8 fv-row'>
                            <select
                              className='form-select form-select-lg form-select-solid'
                              {...formik.getFieldProps(`quotations[${productIndex}].product`)}
                            >
                              <option value=''>Select a Type...</option>
                              {Array.isArray(products) && products.length > 0 ? (
                                products.map((product) => (
                                  <option key={product.id} value={product.id}>
                                    {' '}
                                    {product.name}
                                  </option>
                                ))
                              ) : (
                                <option value='' disabled>No products available</option>
                              )}
                            </select>
                            {!formik.values.quotations[productIndex]?.product && (
                              <>
                                {productValidationError && (
                                  <div className='text-danger'>{productValidationError}</div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {/* Supplier */}
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                            Quantity
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <input
                              type='number'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='Enter Quantity'
                              {...formik.getFieldProps(`quotations[${productIndex}].quantity`)}
                            />
                          </div>
                        </div>
                        <div className='mb-2'>
                          <button
                            type='button'
                            className='btn btn-success mt-2'
                            onClick={() => handleAddVendor(productIndex)}
                          >
                            Add Vendor
                          </button>
                        </div>
                        {formik.values.quotations[productIndex]?.quotationDetailPrices?.length > 0 ? (
                          formik.values.quotations[productIndex].quotationDetailPrices.map((single_product, index) => (
                            <div key={index}>
                              {showNewFields && (
                                <div className='row mb-6 mx-6 col-lg-12'>
                                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                                    Vendor
                                  </label>
                                  <div className='col-lg-4 fv-row'>
                                    <select
                                      className='form-select form-select-lg form-select-solid'
                                      {...formik.getFieldProps(`quotations[${productIndex}].quotationDetailPrices[${index}].supplier`)}
                                      value={formik.values.quotations[productIndex].quotationDetailPrices[index].supplier}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        handleVendorChange(e, productIndex, index);
                                      }}
                                      onBlur={formik.handleBlur}
                                    >
                                      <option value=''>Select a Vendor...</option>
                                      {Array.isArray(vendorArray) && vendorArray.length > 0 ? (
                                        vendorArray.map((vendor) => (
                                          <option key={vendor.id} value={vendor.id}>
                                            {vendor.supplier_name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value='' disabled>No Vendors Available</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className='col-lg-3 fv-row position-relative'>
                                    <span className='currency-style-quotation'>
                                      {getSettingValue('currency_symbol')}
                                    </span>
                                    <input
                                      type='number'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Price'
                                      {...formik.getFieldProps(`quotations[${productIndex}].quotationDetailPrices[${index}].price`)}
                                    />
                                  </div>
                                  <div className="form-check form-check-custom form-check-solid col-lg-3 mb-5 fv-row">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`flexCheckDefault${index}`}
                                      {...formik.getFieldProps(
                                        `quotations[${productIndex}].quotationDetailPrices[${index}].approved`
                                      )}
                                      checked={formik.values.quotations[productIndex]?.quotationDetailPrices[index]?.approved === 1}
                                      onChange={() => handleApproveVendor(productIndex, index)}
                                    />
                                    <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
                                      Recommended
                                    </label>
                                  </div>
                                  <div className='col-lg-2 fv-row mt-2'>
                                    <button
                                      data-toggle="tooltip" data-placement="bottom" title="Remove Vendor"
                                      className="btn btn-icon btn-light-dark btn-active-color-danger btn-sm"
                                      onClick={() => handleRemoveVendor(productIndex, index)}
                                    >
                                      <KTIcon iconName="trash" className="fs-3" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <p className='text-danger'>No vendors added yet for this product.</p>
                        )}
                        {/* Remove Product Button */}
                        {/* <button
                          type='button'
                          className='btn btn-danger '
                          onClick={() => handleRemoveProduct(productIndex)}
                        >
                          Remove Product
                        </button> */}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {/* showing documnts in quotation */}
            <div className='row m-1'>
              <div className='col-lg-4'></div>
              <div className='col-lg-8 card shadow mb-2 mt-4'>
                {formik.values.quotation_documents.map((document, index) => (
                  <div className=' mt-4' style={{ width: '49rem' }} key={index}>
                    <div className='card-header'>
                      <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Documents</h3>
                      </div>
                    </div>
                    <Form className='card-body row'>
                      <div className=' col-lg-8 mt-5' >
                        <div className='form-group'>
                          <div className='row mb-6'>
                            <div className='col-lg-10 fv-row'>
                              <select
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Title'
                                {...formik.getFieldProps(`quotation_documents[${index}].supplier_id`)}
                                disabled
                              >
                                <option value=''>Select a Vendor...</option>
                                {Array.isArray(vendorArray) && vendorArray.length > 0 ? (
                                  vendorArray.map((vendor) => (
                                    <>
                                      {vendorValue.includes(vendor) ? (
                                        <option key={vendor.id} value={vendor.id} disabled className="bg-primary text-white">
                                          {vendor.supplier_name}
                                        </option>
                                      ) : (
                                        <option key={vendor.id} value={vendor.id}>
                                          {vendor.supplier_name}
                                        </option>
                                      )}
                                    </>
                                  ))
                                ) : (
                                  <option value='' disabled>No Vendors Available</option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='row mb-6'>
                            <div className='col-lg-10 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Title'
                                {...formik.getFieldProps(`quotation_documents[${index}].document_title`)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='row mb-6'>
                            <div className='col-lg-10 fv-row'>
                              <input
                                type='textarea'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Description'
                                {...formik.getFieldProps(`quotation_documents[${index}].document_description`)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 mx-6 mt-4'>
                        <div className="symbol symbol-125px me-10 mb-10">
                          <div className="symbol-label fs-6 fw-bold text-success">
                            {renderFileIcon(formik.values.quotation_documents[index].document_path)}
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                      <button
                        data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                        type='button'
                        className='btn btn-danger '
                        onClick={() => handleRemoveDocument(index)}
                      >
                        Remove Document
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* form to add requisition documents  */}
            <div className='row'>
              <div className='col-lg-4'></div>
              <div className='col-lg-8'>
                <div className='card mb-5 mb-xl-10 mt-3 border-primary'>
                  <div className='card-header'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Add Document</h3>
                    </div>
                  </div>
                  <Form ref={formRef} className='card-body'>
                    <div >
                      <div className='form-group'>
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                            <span>Vendor</span>
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <select
                              className='form-select form-select-lg form-select-solid'
                              placeholder='Title'
                              value={documentVendor}
                              onChange={(e) => {
                                setDocumentVendor(e.target.value)
                                if (!e.target.value) {
                                  setDocumentVendorValidation("Vendor is required")
                                } else {
                                  setDocumentVendorValidation("")
                                }
                              }}
                            >
                              <option value=''>Select a Vendor...</option>
                              {Array.isArray(documentvendorArray) && documentvendorArray.length > 0 ? (
                                documentvendorArray.map((vendor) => (
                                  <option key={vendor.id} value={vendor.id}>
                                    {vendor.supplier_name}
                                  </option>
                                ))
                              ) : (
                                <option value='' disabled>No Vendors Available</option>
                              )}
                            </select>
                            <>
                              {documentVendorValidation && (
                                <div className='text-danger'>{documentVendorValidation}</div>
                              )}
                            </>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                            <span>Title</span>
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='Title'
                              value={titleValue}
                              onChange={(e) => {
                                setTitleValue(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            Description
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='Description'
                              value={descriptionValue}
                              onChange={(e) => {
                                setDescriptionValue(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                            File
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <input
                              type='file'
                              className='form-control form-control-lg form-control-solid'
                              onChange={(e) => {
                                HandleImageChange(e)
                                handleFileValidation()
                              }}
                              name='picture'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {!fileValidationError || !documentVendor ? (
                      <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary disabled'>
                        Add Document
                      </button>
                    ) : (
                      <button
                        data-toggle="tooltip" data-placement="bottom" title="Add Document"
                        type='button'
                        className='btn btn-primary'
                        onClick={handleAddDocument}
                        disabled={loading}
                      >
                        {!loading ? 'Add Document' : 'Please wait...'}
                        {loading && (
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Submit Button */}
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button data-toggle="tooltip" data-placement="bottom" title="Add New Quotation" type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? 'Add Quotation' : 'Please wait...'}
              {loading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
};
export default AddQuotation;
